import React from 'reactn';
import Net from '../lib/Net';
import Config from '../Config';
import { Box, Input, Switch, Image, Card, Textarea } from 'bumbag';
import { fetchArchivosCompartidos } from '../services/Archivos';
import { Icon, Button, Heading, Tag } from 'bumbag'
import Andromeda from '../lib/Andromeda';
import { Link } from 'react-router-dom';
import SelectMultiple from '../components/SelectMultiple';
import _ from 'lodash';
import FilePicker from '../components/FilePicker';


export default class SubirArchivo extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
        	grupos: null,
        	grupoSeleccionado: [],
        	solo_docentes: false,
        	nombre: '',
        	descripcion: '',
        	loading: false,
        	archivoSeleccionado: null
        }
    }
      
    async fetchGrupos(){
        this.setState({ gruposSeleccionados: [] });
        var data = await Net.get('/grupos');
        this.setState({
            grupos: data.result.map(g => ({id: g.id.toString(), name: g.nombre}))
        });
    }   

    adjuntarArchivo(files){
        this.setState({archivoSeleccionado: files[0]});
    }

  	componentDidMount(){
        this.fetchGrupos();
    }

    async submit(){
    	if(this.state.loading) return false;
    	if(this.state.grupoSeleccionado.length == 0){
    		alert('Por favor, seleccionar un grupo');
    		return false;
    	}
    	this.setState({loading: true});
    	var grupo_id = this.state.grupoSeleccionado[0].id;
    	var archivo = this.state.archivoSeleccionado;
        let form_data = new FormData();
        
    	form_data.append('archivo', archivo);
    	form_data.append('grupo_id', grupo_id);
    	form_data.append('nombre', this.state.nombre);
    	form_data.append('descripcion', this.state.descripcion);
    	form_data.append('solo_docentes', this.state.solo_docentes ? 1 : 0);
        
        await Net.post('/archivos-grupos', form_data, true);
    	alert('El archivo ha sido enviado y compartido en el grupo.');
    	this.setState({loading: false});
        fetchArchivosCompartidos();
        this.props.history.goBack();
    }
  
  	render(){

        if(!this.state.grupos){
            return (<div>Cargando...</div>);
        }
        
        if(this.state.grupos.length == 0){
        	return (<Box>No hay archivos compartidos</Box>);
        }


       return(
        
            <div style={{maxWidth:400, width: '100%'}}>
                
                <Button marginBottom="major-4" iconBefore="solid-arrow-left" variant="ghost" onClick={() => this.props.history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
                
                <Box>
                   
                    <Heading use="h5" isSubHeading marginBottom="major-4">Compartir un archivo</Heading>

                    <SelectMultiple
                    style={{zIndex: 2, marginTop: 10}}
                    options={this.state.grupos}
                    selected={this.state.grupoSeleccionado}
                    btn_seleccionar_todos={Andromeda.userEsDocente()}
                    txt_button={'Seleccionar un grupo'}
                    txt_placeholder={'Buscar grupo'}
                    txt_empty={'No se encontraron grpos'}
                    handleAddition={(grupo) => this.setState({grupoSeleccionado: [grupo]})}
                    handleDelete={() => this.setState({grupoSeleccionado: []})} />  

                    <Input marginTop={'major-2'} placeholder={'Nombre, ej. Trabajo práctico'} onChange={(e) => this.setState({nombre: e.target.value})} value={this.state.nombre} />

                    <Textarea marginTop={'major-2'} placeholder={'Descripción'} onChange={(e) => this.setState({descripcion: e.target.value})} value={this.state.descripcion} styleContainer={{marginTop:15}} />

                    <Box style={{ width:300, marginBottom: 20, marginTop: 10, alignSelf:'center', flexDirection: 'row' }}>
                        <Switch label={'Solo visible para docentes'} checked={this.state.solo_docentes} onChange={(e) => {this.setState({solo_docentes: e.target.checked})}} />
                    </Box>

                    
                    <FilePicker multiple={false} label={'Seleccionar un archivo'} size={'normal'} onChange={files => this.adjuntarArchivo(files)} />

                    {this.state.archivoSeleccionado &&
                        <Box style={{paddingVertical: 3, paddingHorizontal: 8}} marginTop={'major-2'}>
                             <Tag palette="secondary" style={{marginRight: 5}}>{_.truncate(this.state.archivoSeleccionado.name, {length:15})}</Tag>
                        </Box>
                    }
                    
                    
                    {this.state.archivoSeleccionado &&
                        <Button marginTop="major-2" palette={'primary'} onClick={() => this.submit()} loading={this.state.loading}>Subir archivo</Button>
                    }

                </Box>
    
            </div>
        
        );


  	}
  	
}

