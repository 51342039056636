import React from 'reactn';
import { Link } from 'react-router-dom';
import { fetchMensajesRecibidos } from '../services/Mensajes';
import MensajesSubmenu from '../components/MensajesSubmenu';
import { Text, Spinner, Input, Box } from 'bumbag';
import {Icon as FontAwesome} from 'bumbag';
import { Icon } from 'evergreen-ui';
import 'moment/locale/es';
import Andromeda from '../lib/Andromeda';
import { debounce } from "throttle-debounce";
import { fechaAmigable } from '../lib/Helpers';

export default class Recibidos extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
            refreshing: false,
            busqueda: '',
			loading: false,
        }
        global.limpiarBusqueda = this.limpiarBusqueda;
        this.inputBuscar = React.createRef();  
		this.buscarThrottled = debounce(500, this.buscarMensajes);
  	}

    // funcion global, se tiene que llamar desde el componente menu
    // al hacer click en comunicaciones
    limpiarBusqueda = async () => {
        this.setState({loading: true});
        if(this.inputBuscar && this.inputBuscar.current) this.inputBuscar.current.value = '';
        await this.setGlobal({busquedaRecibidos: ''});
        await fetchMensajesRecibidos();
        this.setState({loading: false});
    }

  	async componentDidMount(){
        this.inputBuscar.current.value = this.global.busquedaRecibidos;   
        await fetchMensajesRecibidos();
	}
	  
	async buscarMensajes(val){
        this.setGlobal({busquedaRecibidos: val});
        if(val.length >= 1 && val.length < 4) return;
        this.setState({ loading: true });	
        await fetchMensajesRecibidos();
        this.setState({ loading: false });	
	}  
	
    
  	render(){


  		return (

	    	<div style={{maxWidth: 600}}>
	    		
	    		<MensajesSubmenu page={'recibidos'} />

	    		<div style={{position: 'relative'}}>
				
                <Input
                placeholder="Buscar..."
                inputRef={this.inputBuscar}
                onChange={e => {
                    this.buscarThrottled(e.target.value);
                }}
                marginBottom="20px" />

                {this.state.loading &&	
                    <div style={{position: 'absolute', top:'7px', right: '9px'}}><Spinner /></div>
                }

                {!this.state.loading &&	this.global.busquedaRecibidos != '' &&
                    <div className={'limpiarBusqueda'} style={{position: 'absolute', top:'10px', right: '9px'}}>
                        <Link onClick={async () => { this.limpiarBusqueda() }}><FontAwesome fontSize={20} icon="regular-times-circle" color="#64409a" /></Link>
                    </div>
                }
					
			</div>

		        {this.global.recibidos &&
		        	<div>
                        {this.global.recibidos.length > 0 &&
                            <div>
                                {this.global.recibidos.map((mensaje, i) => {
                                    
                                    var className = 'listaItem';
                                    if(mensaje.mensaje_admin == 1) className += ' mensajeAdmin';
                                    else if(mensaje.estado < 2) className += ' noLeido';

                                    return (
                                        <Link key={i} onClick={() => this.setGlobal({ mensaje_activo: mensaje})} to={'/mensaje'} style={{textDecoration: 'none'}}>
                                            <div className={className}>
                                                <div style={{marginRight: 90}}>
                                                    <Text className={'asunto'}>{mensaje.padre ? 'Re:' : ''} {mensaje.titulo}</Text><br />
                                                    <Text fontSize="150" color={mensaje.estado < 2 ? '#000' : 'text300'}>{mensaje.emisor_nombre}</Text>
                                                </div>
                                                
                                                <div className="listaItemFecha"><Text fontSize="150" color={mensaje.estado < 2 ? '#000' : 'text300'}>{fechaAmigable(mensaje.fecha)}</Text></div>
                                                
                                                {mensaje.archivos && mensaje.archivos.length > 0 &&
                                                    <div className="listaItemClip"><Icon icon="paperclip" color={'#56748f'} size={14} /></div>
                                                }
                                                
                                                <div style={{opacity: mensaje.estado < 2 ? 1 : 0.5}}>
                                                    {!!mensaje.alumno_nombre && !Andromeda.userEsAlumnoIndependiente() &&
                                                        <div className="pastilla" style={{borderColor: mensaje.division.color || '#555', color: mensaje.division.color || '#333'}}>{mensaje.alumno_nombre.toUpperCase()}</div>
                                                    }

                                                    {!!mensaje.grupos_alumno &&
                                                        <div className="pastilla" style={{borderColor: mensaje.division.color || '#555', color: mensaje.division.color || '#333'}}>{mensaje.grupos_alumno.toUpperCase()}</div>
                                                    }
                                                </div>

                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        }
                        {this.global.recibidos.length == 0 &&
                            <div style={{textAlign: 'center', opacity: 0.8, marginTop: 60}}>No se encontraron mensajes ...</div>
                        }
		    		</div>
		    	}
	    	</div>
		)
  	}
}

