import React from 'reactn';
import Net from '../lib/Net';
import cogoToast from 'cogo-toast';
import { Image, Button, Input, FieldWrapper, Textarea } from 'bumbag'
import defaultProfileImage from '../assets/default-profile.jpg';
import moment from 'moment';
import ImagePicker from '../components/ImagePicker';

export default class Perfil extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
            ready: false,
            loading: false,
            perfil: null
		}
  	}

  	componentDidMount(){
  		this.fetchData();
  	}

  	async fetchData(){
        var data = await Net.get('/perfil');
        this.setState({
            perfil: data.result,
            ready: true
        });
    }

    async guardar(){
        if(this.state.loading) return false;
        
        this.setState({ loading: true });

        let form_data = new FormData();

        if(this.state.subirfoto){
        	form_data.append('foto', this.state.subirfoto);
        }

        form_data.append('nombre', this.state.perfil.nombre);
        form_data.append('apellido', this.state.perfil.apellido);
        form_data.append('telefono', this.state.perfil.telefono || '');
        form_data.append('domicilio', this.state.perfil.domicilio || '');
        form_data.append('fecha_nacimiento', moment(this.state.perfil.fecha_nacimiento).format('YYYY-MM-DD'));
        form_data.append('dni', this.state.perfil.dni || '');
        form_data.append('perfil', this.state.perfil.perfil);
        form_data.append('recibir_emails', this.state.perfil.recibir_emails ? 1 : 0);
        
        var data = await Net.post('/perfil', form_data, true);
        this.setState({ loading: false });
        if(data.status === 'error'){
            cogoToast.error(data.message);
            return false;
        }
        cogoToast.success('Tus datos han sido guardados', {heading: 'Datos actualizados'});
    }

  	render(){

  		if(!this.state.ready){
  			return (<div>Cargando...</div>);
  		}

  		return (
	    	<div style={{maxWidth: 350}}>
	    		<div>

	    			<div style={{textAlign: 'center'}}>

	    				<ImagePicker label={'Seleccionar foto de perfil'} onChange={files => this.setState({subirfoto: files[0]})} value={this.state.perfil.foto_url ? this.state.perfil.foto_url : null} />

		    			<br />
		    			{this.state.perfil.email}
		    			<br />
		    			<br />
		    			
		    		</div>

                
                    <FieldWrapper label="Nombre" marginTop="major-2">
                        <Input value={this.state.perfil.nombre ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, nombre: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="Apellido" marginTop="major-2">
                        <Input value={this.state.perfil.apellido ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, apellido: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="Número de teléfono" marginTop="major-2">
                        <Input value={this.state.perfil.telefono ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, telefono: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="DNI" marginTop="major-2">
                        <Input value={this.state.perfil.dni ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, dni: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="Fecha de nacimiento" marginTop="major-2">
                        <Input value={this.state.perfil.fecha_nacimiento ?? ''} type="date" onChange={e => this.setState({perfil: {...this.state.perfil, fecha_nacimiento: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="Domicilio" marginTop="major-2">
                        <Input value={this.state.perfil.domicilio ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, domicilio: e.target.value}})} />
                    </FieldWrapper>

                    <FieldWrapper label="Perfil" description="Cualquier otra información que quieras mostrar en tu perfil" marginTop="major-2">
                        <Textarea value={this.state.perfil.perfil ?? ''} onChange={e => this.setState({perfil: {...this.state.perfil, perfil: e.target.value}})} />
                    </FieldWrapper>
                    
                    <FieldWrapper textAlign={'right'} marginTop="major-2">
                        <Button isLoading={this.state.loading} onClick={() => this.guardar()} palette={'primary'}>Guardar perfil</Button>
                    </FieldWrapper>



				</div>
	    	</div>
		)

  	}
  	
}

