import { getGlobal, setGlobal } from 'reactn';
import Net from '../lib/Net';

export async function enviarFoto(foto){
	let local_uri = foto;
    let filename = local_uri.split('/').pop();
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;
    let fd = new FormData();
    fd.append('archivo', { uri: local_uri, name: filename, type });
    var data = await Net.post('/archivos', fd, true);
    if(data.status == 'error') return data.message;
    return data.result.id;
}

export async function enviarArchivo(archivo){
	var fd = new FormData();
	fd.append('archivo', { uri: archivo.uri, name: archivo.name, type: 'application/octet-stream' });
    var data = await Net.post('/archivos', fd, true);
    if(data.status == 'error') return data.message;
    console.log('archivo subido');
    console.log(data.result.id);
    return data.result.id;
}



export async function fetchActividadesDocentes(refresh = false){
    setGlobal({ readyActividades: false });

    var filtro_grupos = getGlobal().actividadesFiltroGrupos;
    var filtro_docentes = getGlobal().actividadesFiltroDocentes;

    var data = await Net.get('/actividades-docentes?filtro_grupos=' + filtro_grupos + '&filtro_docentes=' + filtro_docentes);

    
    // concatena actividades nuevas (lazyloading) a menos que se esté haciendo refresh
    // var actividades = getGlobal().actividades ? getGlobal().actividades : [];
    // if(refresh) actividades = [];
    var actividades = [];
    for(var a of data.result){
        actividades.push(a);
    }
    
    var state = { actividades: actividades, readyActividades: true };

    // actualizar la actividad activa, si hay alguna
    if(getGlobal().actividadActiva){
        var actividadActiva = data.result.find(a => a.id == getGlobal().actividadActiva.id);
        if(actividadActiva) state.actividadActiva = actividadActiva;
    }

    
    await setGlobal(state);
}




export async function fetchActividadesAlumnos(refresh = false){
    setGlobal({ readyActividadesAlumnos: false });  

    var data = await Net.get('/actividades/alumno/' + getGlobal().actividadesAlumnoActivo.id);
    
    // concatena actividades nuevas (lazyloading) a menos que se esté haciendo refresh
    // var actividades = getGlobal().actividadesAlumnos ? getGlobal().actividadesAlumnos : [];
    // if(refresh) actividades = [];
    var actividades = [];
    for(var a of data.result){
        actividades.push(a);
    }
    
    var state = { actividadesAlumnos: actividades, readyActividadesAlumnos: true };

    // actualizar la actividad activa, si hay alguna
    if(getGlobal().actividadActiva){
        var actividadActiva = data.result.find(a => a.id == getGlobal().actividadActiva.id);
        if(actividadActiva) state.actividadActiva = actividadActiva;
    }

    await setGlobal(state);
    
}




