import { Box, Icon, Text, Select, Button, Flex } from 'bumbag';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import React from 'reactn';
import Net from '../lib/Net';
import moment from 'moment';
import { fetchActividadesDocentes } from '../services/Actividades';
import Andromeda from '../lib/Andromeda';

export default class ActividadesDocentesScreen extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loadingMore: false,
            allLoaded: false,
            grupos: [],
        }
    }

    async componentDidMount(){    
        await this.fetchGrupos();
        fetchActividadesDocentes();
    }

    async fetchGrupos(){
        var data = await Net.get('/grupos');
        this.setState({
            grupos: data.result.map(g => ({value: g.id.toString(), label: g.nombre}))
        });
    }   

    async cambiarFiltro(obj){
        obj.actividades = null;
        this.setGlobal(obj, async () => {
            await fetchActividadesDocentes(true);
        });
    }


     

    render() {

        
        // segurarse que el filtro seleccionado no tiene un valor incorrecto

        return (
            <Box style={{maxWidth: 700, padding: 20, flex: 1, flexDirection: 'column', backgroundColor: '#fff'}}>
            
                <Flex justifyContent="space-between" marginBottom={'major-2'}>
                    <Flex>
                        {this.state.grupos.length > 0 &&
                            <Box marginRight={'major-2'}>
                                <Select
                                options={[{value: 'todos', label: 'En todos mis grupos'}, ...this.state.grupos]}
                                placeholder={'Filtrar por grupo...'}                            
                                value={this.state.grupos.find(g => g.value == this.global.actividadesFiltroGrupos) ? this.global.actividadesFiltroGrupos : 'todos'}
                                onChange={e => this.cambiarFiltro({ actividadesFiltroGrupos: e.target.value})}
                                />
                            </Box>
                        }
                        <Box>
                            <Select
                            options={[{value: 'mias', label: 'Mis actividades'}, {value: 'otros', label: 'de otros docentes'}, {value: 'todos', label: 'Todas'}]}
                            placeholder={'Filtrar por docente...'}                            
                            value={this.global.actividadesFiltroDocentes}
                            onChange={e => this.cambiarFiltro({ actividadesFiltroDocentes: e.target.value})}
                            />
                        </Box>
                    </Flex>
                    <Box>
                        <Link to={'/form-actividad'}><Button palette="secondary">Nueva actividad</Button></Link>
                    </Box>
                </Flex>
                
                {!this.global.actividades &&
                    <Box><Text>cargando...</Text></Box>
                }

                {this.global.actividades && this.global.actividades.length > 0 &&
                    
                    <div>
                    
                        {this.global.actividades.map((actividad, i) => {

                            var className = 'listaItem';
                            if(actividad.borrador == 1) className += ' borrador';

                            return (

                                <Link key={i} style={{minHeight: 60, flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: '#EEEEEE', paddingVertical: 10}}
                                onClick={() => {  this.setGlobal({actividadActiva: actividad}); }} to={'/actividad/' + actividad.id}>
                                    
                                    <div className={className}>
                                    
                                        <Box style={{paddingRight: 60}}>
                                            
                                            <Box style={{flexDirection: 'row', marginBottom: 5}}>
                                                <Text style={{fontWeight: 'bold'}}>{_.truncate(actividad.nombre, {length: 50})}</Text>
                                            </Box>

                                            {!!actividad.docente && actividad.docente_id != this.global.user.id &&
                                                <Text style={{marginBottom: 5, opacity: 0.5, fontSize: 12, marginRight: 5}}>{actividad.docente.apellido + ' ' + actividad.docente.nombre}
                                                {!!actividad.espacio_curricular && <Text>, {_.truncate(actividad.espacio_curricular.nombre)}</Text>}</Text>
                                            }
                                            
                                            {!!actividad.grupo &&
                                                <div className="pastilla" style={{borderColor: '#aaa', color: '#aaa'}}>{actividad.grupo.nombre}</div>
                                            }

                                            
			                                <div className={'pastilla'} style={{backgroundColor: Andromeda.actividadStatusColor(actividad.aprobada)}}>{Andromeda.actividadStatusText(actividad.aprobada)}</div>
			                                
                                            
                                        </Box>
                                        
                                        {actividad.calificacion_cerrada == 1 &&
                                            <Box style={{position: 'absolute', top: 40, right: 10}}>
                                                <Icon aria-label="Settings" color={'#4dd966'} icon="solid-check" />
                                            </Box>
                                        }
                                        
                                        {!!actividad.fecha_inicio && 
                                            <Box style={{opacity: 0.5, position: 'absolute', top: 10, right: 10}}>
                                                <Box>{ moment(actividad.fecha_inicio).format('DD/MM/YYYY') }</Box>
                                            </Box>
                                        }

                                        {actividad.docente_id == this.global.user.id && 
                                            <Box style={{opacity: 0.5, position: 'absolute', right: 10, top: 35}}>
                                                {actividad.entregas_count} entrega{actividad.entregas_count != 1 ? 's' : ''}
                                            </Box>
                                        }

                                    </div>

                                </Link>);

                            }

                        )}
                        
                    </div>
                }

                {this.global.actividades && this.global.actividades.length == 0 &&
                    <Box style={{paddingVertical: 40}}>
                        <Text style={{textAlign: 'center', opacity: 0.5}}>No hay actividades por el momento...</Text>
                    </Box>
                }

                
            </Box>
        );

    }

}
