
import React, { useState, useEffect, useGlobal } from 'reactn';
import { useHistory } from 'react-router-dom';
import { fetchConceptos } from '../services/Calificaciones';
import Net from '../lib/Net';
import { Box, Text, Flex, Select, Icon } from 'bumbag';
import _ from 'lodash';

export default function CalificacionesDocentes(props){

    const [grupos, setGrupos] = useState([]);
    const [espaciosCurriculares, setEspaciosCurriculares] = useState([]);
    const [conceptosFiltroGrupos, setConceptosFiltroGrupos] = useGlobal('conceptosFiltroGrupos');
    const [conceptosFiltroEC, setConceptosFiltroEC] = useGlobal('conceptosFiltroEC');
    const [conceptos] = useGlobal('conceptos');
    const [conceptoActivo, setConceptoActivo] = useGlobal('conceptoActivo');
    const history = useHistory();
    

    useEffect(() => {

        init();

    }, []);


    const init = async () => {
        var grupos = await fetchGrupos();
        fetchEC(grupos[0].id);
        fetchConceptos();
    }


    const fetchGrupos = async () => {

        // recupera grupos 
        var grupos = await Net.get('/grupos');
        if(grupos.result.length == 0){
            alert('Todavía no formas parte de nigún grupo, por favor comunicarse con la institución');
            return false;
        }

        setGrupos(grupos.result.map(g => ({value: g.id.toString(), label: g.nombre})));

        return grupos.result;

    }   


    const fetchEC = async (grupo_id) => {
        
        // recupera espacios curriculares
        var ec = await Net.get('/espacios-curriculares?grupo_id=' + grupo_id);
        setConceptosFiltroEC('todos');
        setEspaciosCurriculares(ec.result.map(g => ({value: g.espacio_curricular.id.toString(), label: g.espacio_curricular.nombre})));

    } 

    
    console.log(espaciosCurriculares);
    
    return (

        <Box maxWidth="600px">

            <Flex>

                {grupos.length > 0 &&
                    <Box>
                        <Select
                        placeholder="Filtrar por grupo..."
                        value={conceptosFiltroGrupos}
                        options={[{value: 'todos', label: 'En todos mis grupos'}, ...grupos]}
                        onChange={async e => {
                            fetchEC(e.target.value);
                            await setConceptosFiltroGrupos(e.target.value);
                            await fetchConceptos();
                        }}>
                        </Select>
                    </Box>

                }

                {espaciosCurriculares.length > 0 &&

                    <Box marginLeft="major-2">
                        <Select
                        placeholder="Filtrar por espacio curricular..."
                        value={conceptosFiltroEC}
                        options={[{value: 'todos', label: 'Todos los espacios curriculares'}, ...espaciosCurriculares]}
                        onChange={async e => {
                            await setConceptosFiltroEC(e.target.value);
                            await fetchConceptos();
                        }}>
                        </Select>
                    </Box>

                }

            </Flex>


            {!conceptos &&
                <Box>cargando...</Box>
            }

            {!!conceptos && conceptos.length == 0 &&
                <Box marginTop="major-4">No hay conceptos calificables por el momento...</Box>
            }

            {!!conceptos && conceptos.length > 0 &&
                <Box marginTop="major-2">
                    {conceptos.map((concepto, i) => {
                        return (

                            <a key={i} onClick={async () => {await setConceptoActivo(concepto); history.push('concepto')}} style={{minHeight: 60, flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: '#EEEEEE', paddingVertical: 10}}>
                                
                                <div className="listaItem">
                                
                                    <Box style={{paddingRight: 60}}>
                                        
                                        <Box style={{flexDirection: 'row', marginBottom: 5}}>
                                            <Text style={{fontWeight: 'bold'}}>{_.truncate(concepto.nombre, {length: 50})}</Text>
                                        </Box>

                                        
                                        {!!concepto.espacio_curricular &&
                                            <div className="pastilla" style={{borderColor: '#aaa', color: '#aaa'}}>{concepto.espacio_curricular.nombre}</div>
                                        }

                                        {!!concepto.grupo &&
                                            <div className="pastilla" style={{borderColor: '#aaa', color: '#aaa'}}>{concepto.grupo.nombre}</div>
                                        }

                                        {concepto.calificacion_cerrada == 1 &&
                                            <Box style={{position: 'absolute', top: 25, right: 10}}>
                                                <Icon color={'#4dd966'} icon="solid-check" />
                                            </Box>
                                        }

                                        
                                    </Box>
                                    
                                </div>

                            </a>

                        );
                    })}
                </Box>
            }


            
        </Box>


    );


}