import React from 'reactn';
import Net from '../lib/Net';
import Config from '../Config';
import { Box, Image } from 'bumbag';

export default class Acerca extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
			data: null
		}
  	}

  	async fetchData(){
        var data = await Net.get('/configuracion');
        this.setState({
            data: data.result
        });
    }

    componentDidMount(){
    	this.fetchData();
    }
  
  	render(){

  		if(!this.state.data){
  			return (<div>Cargando...</div>);
  		}
  		
  		return (
  			<Box>
	  			<Box textAlign="center">
	  				<Image src={Config.url_img + '/' + this.state.data.acerca_imagen} marginBottom={5} width={100} height={100} fit="cover" borderRadius={100} />
	  			</Box>
	  			<div dangerouslySetInnerHTML={{__html: this.state.data.acerca_contenido}}></div>
	  		</Box>
		)
  	}
  	
}

