import React, { useState, useGlobal } from 'reactn';
import { Icon, toaster } from 'evergreen-ui';
import { Box, Heading, Button, Alert, Textarea, Tag } from 'bumbag';
import Net from '../lib/Net';
import Andromeda from '../lib/Andromeda';
import { fetchActividadesAlumnos } from '../services/Actividades';
import FilePicker from '../components/FilePicker';

export default props => {

    const [data, setData] = useState(null);
    const [contenido, setContenido] = useState('');
    const [loading, setLoading] = useState(false);
    const [archivos, setArchivos] = useState([]);
    const [actividadActiva] = useGlobal('actividadActiva');
    const [actividadesAlumnoActivo] = useGlobal('actividadesAlumnoActivo');
    
    const eliminarArchivo = async (archivo_id) => {

        if(!window.confirm('¿Seguro que deseas eliminar este archivo?')) return false;
        await Net.get('/actividades-archivos/' + archivo_id + '/eliminar');
        await fetchActividadesAlumnos();

    }


    const adjuntarArchivos = (files) => {
    	var adjuntos = archivos.slice();
    	files.map(file => {
    		var agregar = true;
	    	archivos.map(archivo => {
	    		if(archivo.name === file.name) agregar = false;
	    	});
	    	if(agregar) adjuntos.push(file);
    	});
	    setArchivos(adjuntos);
    }

    const enviarArchivo = async (archivo, url) => {
        let fd = new FormData();
        fd.append('archivo', archivo);
        var data = await Net.post(url, fd, true, true);
        return data.result.id;
    }

    const submit = async () => {

        if(loading) return false;        
        
        if(archivos.length == 0 && contenido == ''){
            toaster.danger('Por favor ingresar el contenido o adjuntar archivos');
            return false;
        }

        if(!window.confirm('Estas por enviar el trabajo al docente, asegurate haber escrito tu respuesta y de haber adjuntado los archivos correctamente. ¿Enviamos el trabajo?')) return false;

        
        setLoading(true);
        
        
        // envia form
        let form_data = new FormData();
        form_data.append('contenido', contenido);
        form_data.append('user_id', actividadesAlumnoActivo.id);

        // subir archivos
        var archivos_ids = [];
        var promises_archivos = archivos.map(async (archivo) => {
            var archivo_id = await enviarArchivo(archivo, '/actividades-archivos?user_id=' + actividadesAlumnoActivo.id + '&actividad_id=' + actividadActiva.id);
            archivos_ids.push(archivo_id);
        });
        await Promise.all(promises_archivos);
        form_data.append('archivos_ids', archivos_ids.join());

        await Net.post('/actividades/' + actividadActiva.id + '/enviar-entrega', form_data, true);

        setArchivos([]);
        setContenido('');

        await fetchActividadesAlumnos(true);
        
        toaster.success('¡Muy bien! Has enviado la tarea 🎉');

        props.history.push('/actividades-alumnos');

        setLoading(false);

    }

    return (

        <Box maxWidth="600px" widht="100%">

            <Box>
                <Button iconBefore="solid-arrow-left" variant="ghost" onClick={() => props.history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
            </Box>
        
            {Andromeda.userEsTutor() &&
                <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entrega de {actividadesAlumnoActivo.apellido} {actividadesAlumnoActivo.nombre} para {actividadActiva.nombre}</Heading>
            }

            {Andromeda.userEsAlumnoIndependiente() &&
                <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entrega para {actividadActiva.nombre}</Heading>
            }


            <Alert marginBottom="major-4">¿Ya terminaste la actividad? Desde aquí podés enviarla. Todo lo que mandes solo será visto por el/la profe.</Alert>

            <Textarea
            rows="10"       
            onChange={e => setContenido(e.target.value)}
            value={contenido} />

            <FilePicker onChange={files => adjuntarArchivos(files)} />  
            <Box marginTop="major-2">
                {archivos.map((a, i) => {
                    return (<Tag palette="secondary" style={{marginRight: 5}} key={i} onRemove={() => this.quitarArchivo(a)}>{a.name}</Tag>);
                })}
            </Box> 


            <Button  marginTop="major-4" palette="primary" isLoading={loading} onClick={() => submit()}>Enviar entrega</Button>         

        </Box>

    );


}