import React from 'react';
import '../App.css';
import { Pane } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { Button, Group } from 'bumbag';


export default function(props) {

	const history = useHistory();
	
	if(props.page === 'mensaje'){
		return (
			<Pane marginBottom={20}>
				<Button iconBefore="solid-arrow-left" variant="ghost" onClick={() => history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
			</Pane>
		);
	}

  	return (

  			<Group paddingBottom="major-3">
  				<Button palette={props.page === 'recibidos' ? 'secondary' : 'default'} onClick={() => history.push('/recibidos')}>Recibidos</Button>
  				<Button palette={props.page === 'enviados' ? 'secondary' : 'default'} onClick={() => history.push('/enviados')}>Enviados</Button>
  				<Button palette={props.page === 'nuevo' ? 'secondary' : 'default'} onClick={() => history.push('/nuevo-mensaje')}>Enviar un mensaje</Button>
  			</Group>
  	);

}

