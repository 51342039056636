import React, { useState, useEffect, useGlobal } from 'reactn';
import { fetchCalificacionesConcepto, fetchConceptos } from '../services/Calificaciones';
import { Icon, toaster } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { Box, Heading, Button } from 'bumbag';
import Net from '../lib/Net';

export default function Concepto(){

    const [loading, setLoading] = useState(false);
    const [conceptoActivo, setConceptoActivo] = useGlobal('conceptoActivo');
    const history = useHistory();
    const [user] = useGlobal('user');

    useEffect(() => {
        init();
    }, []);

    const init = async() => {
        var concepto = conceptoActivo;
        await fetchCalificacionesConcepto(concepto.id);   
    }

    const eliminarConcepto = async() => {
        if(!window.confirm('Estas por eliminar este concepto. Se eliminarán tambien todas las calificaciones existentes. ¿Deseas continuar?')){
            return false;
        }
        var concepto = conceptoActivo;
        if(!concepto.borrador && concepto.calificacion_cerrada != 0){
            toaster.danger('Este concepto no se puede eliminar porque las calificaciones están cerradas');
            return false;
        }
        await Net.get('/calificaciones/conceptos/' + concepto.id + '/eliminar');
        await fetchConceptos();
        history.goBack();
    }

    const duplicarConcepto = async () => {
        var concepto = conceptoActivo;
        await Net.get('/calificaciones/conceptos/' + concepto.id + '/duplicar');
        await fetchConceptos();
        toaster.success('El concepto ha sido duplicado');
        history.goBack();
    }

    const guardarCalificaciones = async () => {
        if(!window.confirm('Una vez enviadas, las calificaciones no se podrán modificar y serán visibles por directivos y tutores. ¿Deseas continuar?')){
            return false;
        }
        setLoading(true);
        var concepto = conceptoActivo;
        let form_data = new FormData();
        form_data.append('concepto_id', concepto.id);
        await Net.post('/cerrar-calificaciones', form_data, true);
        fetchConceptos();
        setLoading(false);
        history.goBack();
    }

    if(!conceptoActivo) return (<Box>cargando...</Box>);

    var concepto = conceptoActivo;
    var is_owner = concepto.docente_id == user.id;
    var grupo = concepto.grupo;

    return (
        <Box maxWidth="600px">
            <Box>
                <Button marginBottom="major-4" iconBefore="solid-arrow-left" variant="ghost" onClick={() => history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
            </Box>
            <Box>
                <Heading use="h5" isSubHeading marginBottom="major-4">Calificaciones de {concepto.nombre} en {grupo.nombre}</Heading>
            </Box>


            <Box style={{border: '1px solid #ddd', borderRadius: 5}} marginBottom="major-4">    
                <Box padding="major-1">
                    <span style={styles.rowLabel}>Concepto</span>
                    <span style={styles.rowContent}>{concepto.nombre}</span>
                </Box>
                {!!concepto.grupo &&
                    <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                        <span style={styles.rowLabel}>Grupo</span>
                        <span style={styles.rowContent}>{concepto.grupo.nombre}</span>
                    </Box>
                }
                {!!concepto.espacio_curricular &&
                    <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                        <span style={styles.rowLabel}>Espacio curricular</span>
                        <span style={styles.rowContent}>{concepto.espacio_curricular.nombre}</span>
                    </Box>
                }
            </Box>

            {!!concepto.calificaciones &&
                <Box>
                    {concepto.calificaciones.map((calificacion, i) => {
                        return (

                            <a key={i} onClick={() => { if(calificacion.calificacion_cerrada != 1 && is_owner) history.push('calificar', { calificacion: calificacion })}} style={{minHeight: 60, flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: '#EEEEEE', paddingVertical: 10}}>
                                
                                <div className="listaItem">
                                
                                    <Box style={{paddingRight: 60}}>
                                        
                                        <Box style={{flexDirection: 'row', marginBottom: 5, fontWeight: 'bold'}}>
                                            {calificacion.alumno.apellido} {calificacion.alumno.nombre}
                                        </Box>

                                        <div className="pastilla" style={{backgroundColor: calificacion.valor.exitoso == 1 ? '#a7f3d0' : '#f8d2d0', color: 'rgba(0,0,0,0.5)'}}>{calificacion.valor.nombre}</div>
                                                                            
                                    </Box>
                                    
                                </div>

                            </a>

                        );
                    })}
                </Box>
            }



            {!!concepto.calificaciones &&
                <Box marginTop="major-4">
                    {concepto.calificacion_cerrada == 0 && is_owner &&
                        <Box textAlign="right">
                            <Button onClick={() => history.push('calificar')} marginRight="major-2">Calificar</Button>
                            {!!concepto.calificaciones && concepto.calificaciones.length > 0 &&
                                <Button isLoading={loading} onClick={() => guardarCalificaciones()}>Enviar calificaciones</Button>
                            }
                        </Box>
                    }
                    {concepto.calificacion_cerrada == 1 &&
                        <Box>Las calificaciones para este concepto están cerradas y no se pueden modificar.<br />Para volver a abrir las calificaciones comunicarse con dirección.</Box>
                    }
                </Box>
            }

        </Box>

    )

}


const styles = {
	rowLabel: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 150
	},
	
	rowContent: {
	
	}
}
