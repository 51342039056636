import React from 'reactn';
import { Box } from 'bumbag';



export default class ChatBubble extends React.Component{
	
    constructor(props){
		super(props);
	}

	render(){

		return(

			<Box>

                {this.props.direccion == 'enviado' &&


                    <Box style={{textAlign: 'right', paddingRight: 20}}>
                        <Box style={{
                            display: 'inline-block',
                            backgroundColor: "#0078fe",
                            padding: 10,
                            borderRadius: 5,
                            marginTop: 5,
                            borderRadius: 20,
                            maxWidth: '50%',
                            fontSize: 12,
                            color: "#fff",
                        }}> {this.props.text}</Box>
                    </Box>

                }

                {this.props.direccion != 'enviado' &&
                    <Box style={{textAlign: 'left', paddingLeft: 20}}>
                        <Box style={{
                            display: 'inline-block',
                            backgroundColor: "#dedede",
                            padding: 10,
                            borderRadius: 5,
                            marginTop: 5,
                            borderRadius: 20,
                            maxWidth: '50%',
                            fontSize: 12,
                            color: "#000",
                        }}> {this.props.text}</Box>
                    </Box>
                }

            </Box>

		)

	}
}
