import React from 'react'
import Config from '../Config';
import { Button, Tag, Input, Modal, Dialog, Box, Flex } from 'bumbag';


export default class SelectMultiple extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {
			modalVisible: false,
			query: ''
		}
	}

	toggleItem(item){
        var index = this.isItemSelected(item);
        if(index !== -1){
            this.props.handleDelete(index);
        }else{
            this.props.handleAddition(item);
        }
	}

	removeItem(i){
		this.props.handleDelete(i);
	}

    parseSuggestions(){
        var suggestions = [];
		this.props.options.map((item, i) => {
			var pass = true;
			var query = this.state.query.toUpperCase();
			if(query !== '' && !item.name.toUpperCase().includes(query)){
				pass = false;
			}
			if(pass){
				suggestions.push(item);
            }
        });
        return suggestions;
    }

    isItemSelected(item){
        var is_selected = -1;
        this.props.selected.map((option, i) => {
            if(option.id === item.id){
                is_selected = i;
            }
        });
        return is_selected;
    }

    selectedList(){
    	var extraPill = null;
    	if(this.props.showExtraPill){
    		extraPill = <Tag palette="warning" style={{marginRight: 5}}>{this.props.textExtraPill}</Tag>;
    	}
        if(this.props.selected.length === 0) return extraPill;
        if(this.props.selected.length >= 50)return (<div>
        	{extraPill}
            <div style={{marginTop: 20}}>{this.props.selected.length} seleccionados</div>
        </div>);

        return (
            <div style={{marginTop: 5, marginBottom: 0}}>
            	{extraPill}
                {this.props.selected.map((tag, i) => {
                	return (<Tag palette="secondary" style={{marginRight: 5, marginTop: 5}} key={i} onRemove={() => this.removeItem(i)}>{tag.name}</Tag>);
                })}
            </div>
        )
    }



	render(){
		
		var suggestions = this.parseSuggestions();
		
		return (
			
			<div style={this.props.style}>


                        <Modal.State>

                            <Modal.Disclosure use={Button}>{this.props.txt_button}</Modal.Disclosure>
                            
                            <Dialog.Modal minWidth="500px" footer={<Flex width="100%" alignItems={'center'}>
                                <Box style={{width: '50%'}}>{this.props.extra}</Box>
                                <Box style={{width: '50%', textAlign: 'right'}}><Modal.Disclosure use={Button}>Aceptar</Modal.Disclosure></Box>
                                </Flex>}>
                            
                                <Box>
                                
                                    <div style={{minWidth: window.innerWidth < 400 ? '60vw' : '300px', paddingBottom: 10}}>
                                        {this.props.btn_seleccionar_todos && this.props.selected.length < this.props.options.length &&
                                            <Button variant={'ghost'} size={'small'} onClick={() => this.props.handleSelectAll()}>Seleccionar todos</Button>
                                        }

                                        {this.props.btn_seleccionar_todos && this.props.selected.length === this.props.options.length && this.props.options.length > 0 &&
                                            <Button variant={'ghost'} size={'small'} onClick={() => this.props.handleUnSelectAll()}>Deseleccionar todos</Button>
                                        }
                                    </div>
                                    
                                    {this.props.options.length > 10 &&
                                        <Input value={this.state.query} placeholder={this.props.txt_placeholder} onChange={ e => this.setState({query: e.target.value}) } />
                                    }

                                    <div style={{marginTop: 0, marginBottom: 20, maxHeight: '51vh', overflowY: 'scroll'}}>							
                                        {suggestions.map((item, index) => {
                                            return (<Button size="small" palette={this.isItemSelected(item) !== -1 ? 'default' : 'default'} marginTop={'major-1'} marginRight={'major-1'} key={index} onClick={() => this.toggleItem(item)}>{item.name}</Button>);
                                        })}
                                    </div>

                                    {this.selectedList()}
                            
                                </Box>

                            </Dialog.Modal>
                       
                        </Modal.State>

				{this.selectedList()}


			</div>
		)

	}
}

