import { getGlobal } from 'reactn';
import Config from '../Config';

export default {

    userEsDocente: () => {
    	return getGlobal().rol.rol === 'docente';
    },

    userEsTutor: () => {
    	return getGlobal().rol.rol === 'tutor';
    },

    userEsAlumnoIndependiente: () => {
    	return getGlobal().rol.rol === 'alumno';
    },

    userEsAlumnoIndOTutor: () => {
    	return (this.userEsAlumnoIndependiente() || this.userEsTutor())
    },

    tieneModulo: (slug_param) => {
        var tiene_modulo = false;
        var rol_activo = getGlobal().rol;
        if(rol_activo.cliente.modules !== undefined){
            rol_activo.cliente.modules.map((item, i) => {
                if(item.slug == slug_param){
                    tiene_modulo = true;
                }
            });
        }
        return (tiene_modulo);
    },
    
    imageUrl: (id, size) => {
        return Config.url_img + '/' + size + '/' + id
    },

    messageStatusText: function(value){
        if(value === 0){
            return 'Esperando autorización'
        }
        if(value === 1){
            return 'Enviado'
        }
        if(value === 2){
            return 'Leído'
        }
        if(value === 3){
            return 'Confirmado'
        }
    },

    messageStatusColor: function(value){
        if(value === 0){
            return '#fedf6f'
        }
        if(value === 1){
            return '#b3e7f0'
        }
        if(value === 2){
            return '#b3f0be'
        }
        if(value === 3){
            return '#c4f189'
        }
    },

    actividadStatusText: function(value){

        if(value === 1){
            return 'Publicada'
        }else{
            return 'Esperando autorización'
        }
        
    },

    actividadStatusColor: function(value){

        if(value === 1){
            return '#b3e7f0'
        }else{
            return '#fedf6f'
        }
        
    }

}