import React from 'reactn';
import { Link } from 'react-router-dom';
import { fetchMensajesEnviados } from '../services/Mensajes';
import MensajesSubmenu from '../components/MensajesSubmenu';
import { Text, Spinner } from 'bumbag';
import { Icon } from 'evergreen-ui';
import moment from 'moment';
import 'moment/locale/es';
import { fechaAmigable } from '../lib/Helpers';
import Andromeda from '../lib/Andromeda';

export default class Enviados extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
            search: '',
            refreshing: false
        }
  	}

  	async componentDidMount(){
  		await fetchMensajesEnviados();
  	}

    filterSearch(){
    	if(!this.global.enviados) return [];
    	var data = this.global.enviados;
    	if(this.state.search !== ''){
    		data = data.filter((item) => {
    			var term = this.state.search.toLowerCase();
    			var match = false;
    			if(item.titulo && item.titulo.toLowerCase().includes(term)){
    				match = true;
    			}
    			if(item.emisor_nombre.toLowerCase().includes(term)){
    				match = true;
    			}
    			return match;
    		});
    	}
    	return data;
    }

    clearSearch(){
    	this.setState({search: ''});
    }
    
  	render(){

  		
  		if(!this.global.enviados){
        	return (<div><Spinner /></div>)
        }
		
		var data = this.filterSearch();
        
  		return (
	    	<div style={{maxWidth: 600}}>

	    		<MensajesSubmenu page={'enviados'} />

				{data.length === 0 &&
        			<div className="text-gray-500">No hay mensajes enviados</div>
        		}
	    		
	    		{data.map((mensaje, i) => {
	    			return (
	    				<Link key={i} onClick={() => this.setGlobal({ mensaje_activo: mensaje})} to={'/mensaje'} style={{textDecoration: 'none'}}>
			    			<div className={'listaItem'}>
			    				<Text color="text300"><b>{mensaje.titulo}</b></Text><br />
			    				<Text fontSize="150" color="text300">{mensaje.destinatarios}</Text>
			    				<div className="listaItemFecha"><Text fontSize="150" color="text300">{fechaAmigable(mensaje.fecha)}</Text></div>
			    				{mensaje.archivos && mensaje.archivos.length > 0 &&
			    					<div className="listaItemClip"><Icon icon="paperclip" color={'#56748f'} size={14} /></div>
			    				}
			    				{Andromeda.userEsDocente() &&
			                        <div>
			                            <div className={'pastilla'} style={{backgroundColor: Andromeda.messageStatusColor(mensaje.estado)}}>{Andromeda.messageStatusText(mensaje.estado)}</div>
			                        </div>
			                    }
			    			</div>
						</Link>
					);
	    		})}
	    	</div>
		)
  	}
}

