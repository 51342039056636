import React from 'reactn';
import '../App.css';
import MainMenu from '../components/MainMenu';
import MainMenuOptions from '../components/MainMenuOptions';
import { Columns, Column, Box, Select } from 'bumbag';
import { Pane, Heading } from 'evergreen-ui';
import Router from '../navigation/Router';
import { seleccionarRol } from '../lib/Helpers';
import _ from 'lodash';
import { fetchMensajesRecibidos } from '../services/Mensajes';
import logo from '../assets/logo-color.png';
import Net from '../lib/Net';

export default class Home extends React.Component{

	async componentDidMount(){
		if(this.global.pushToken){
			// guardar el token push en el server
			var params = {
	            web_token: this.global.pushToken,
	            rol: this.global.rol.users_roles_id
	        }
			await Net.post('/registrar-dispositivo', params);
		}else{
			// no hay push token, notificaciones no soportadas
			// hacemos pooling al server cada x minutos
			// el server tiene cache redis, de esta forma podemos ampliar
			// el uso de la app web a safari (todos los que tiene iphone lo van a usar)
			setInterval(() => {
				fetchMensajesRecibidos();	
			}, 1000 * 60 * 2);
		}
	}

	render(){

		return(
			<Pane
	    	display="flex"
	    	height={window.innerHeight}
	  		justifyContent="flex-start"
	  		flexDirection="column"
	  		alignItems={'stretch'}
	  		alignContent={'stretch'}>

		    	<Pane display="flex" flexWrap="wrap" padding={23} minHeight={80} background="purpleTint" borderBottom={'default'}>
				  	<Pane minWidth={16}>
				  		<MainMenu />
				  	</Pane>
				  	<Pane flex={1} alignItems="center">
				    	<Heading size={500}>{this.global.user.apellido} {this.global.user.nombre}</Heading>
                        <Heading size={200} marginTop={3}>{ _.capitalize(this.global.rol.rol) + (this.global.rol.division ? ' en ' + this.global.rol.division.nombre : '') + ' • ' + this.global.rol.cliente.name}</Heading>
				  	</Pane>
				  	<Pane>
				  		{this.global.user.roles.length > 1 &&
				  			<div className={'selectRolContainer'}>
					  			<Select
								options={this.global.user.roles.map(rol => {return {value: rol.users_roles_id, label: _.capitalize(rol.rol) + (rol.division ? ' en ' + rol.division.nombre : '') + ' • ' + rol.cliente.name }})}
								value={this.global.rol.users_roles_id.toString()}
								onChange={e => {seleccionarRol(e.target.value)}}
								/>
							</div>
				  		}
				  	</Pane>
				</Pane>
		    	<Pane padding={24}>
		    		<Columns>
  						<Column className={'columnaMenu'} borderRight={'1px solid #eee'}>
  							<Box height={'100%'} marginTop={'-3px'} paddingRight={5}>
  								<MainMenuOptions />
  								<a href="https://goandromeda.io" target="_blank" className="inAppLogo"><img src={logo} /></a>
  							</Box>
  						</Column>
  						<Column className={'columnaContenido'}>
  							<Box marginTop={'5px'} paddingBottom={10}>
  								<Router />
  							</Box>
  						</Column>
  					</Columns>
		    	</Pane>
		    </Pane>);
	}
}

