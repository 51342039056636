import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
	apiKey: "AIzaSyBxemiFQfmdq2htPzHWL-mN38wvGJw-v7U",
    authDomain: "andromeda-36c22.firebaseapp.com",
    databaseURL: "https://andromeda-36c22.firebaseio.com",
    projectId: "andromeda-36c22",
    storageBucket: "andromeda-36c22.appspot.com",
    messagingSenderId: "67330795491",
    appId: "1:67330795491:web:6cc5085499d6d7f32562d6"
});
var messaging = null;
try{
	messaging = initializedFirebaseApp.messaging();
	messaging.usePublicVapidKey(
		// Project Settings => Cloud Messaging => Web Push certificates
  		"BHuQKNdiHNyUU2vD_VGnvERGJ4AJifyCCcSSVaUJvPYbV9yayY0lym0LLq2ZstgKsNiNZ4WZ_hfpPJ3D1uS1UZ8"
	);
}catch(e){
	console.log('navegador no soportado');
}
export { messaging };