import React, { useState, useEffect } from 'react';
import { Box, Select, Text } from 'bumbag';
import moment from 'moment';
import Net from '../lib/Net';


export default function CalificacionesAlumnos(props){

    const [data, setData] = useState(null);
    const [filtroAlumnos, setFiltroAlumnos] = useState('');
    const [alumnos, setAlumnos] = useState([]);

    const fetchData = async () => {
        var data = await Net.get('/calificaciones-alumnos');
        setData(data.result);
        setAlumnos(data.result.map(a => ({value: a.id.toString(), label: a.apellido + ' ' + a.nombre})));
        setFiltroAlumnos(data.result.length > 0 ? data.result[0].id.toString() : '');
    }

    useEffect(() => {
        fetchData();
    }, []);


    if(!data){
        return(
        <div>cargando...</div>);
    }

    if(data && data.length == 0){
        return(
        <div>No hay información disponible</div>);
    }

    // data != null && data.length > 0
    return (<Box>
        
        <Select placeholder="filtrar por alumnos" options={alumnos} value={filtroAlumnos} onChange={e => setFiltroAlumnos(e.target.value)}></Select>
        
        {filtroAlumnos != '' &&
            <>
                {data.map((alumno, i) => {

                    console.log('alumno:');
                    console.log(alumno);
                    console.log(filtroAlumnos);

                    if(alumno.id != filtroAlumnos) return;

                    if(alumno.calificaciones.length == 0){
                        return (<Box marginTop="major-4">No hay calificaciones por el momento</Box>);
                    }
                    return (<Box maxWidth="600px" marginTop="major-4">
                        {alumno.calificaciones.map((item, j) =>
                            <Box position="relative" paddingTop="15px" paddingBottom="15px" borderBottom="1px solid #eee">
                                <b>{item.concepto.nombre}</b><br />
                                {item.concepto.espacio_curricular &&
                                    <Text opacity={0.5}>{item.concepto.espacio_curricular.nombre}</Text>
                                }
                                <Box position="absolute" right="10px" top="15px">
                                    <Box backgroundColor={item.valor.exitoso == 1 ? '#d3eba7' : '#fdd1d1'} textAlign="center" paddingLeft="5px" paddingRight="5px" marginBottom="3px" borderRadius="5px">
                                        <Text style={{color: '#000', opacity: 0.5, fontWeight: 'bold', fontSize: 11}}>{item.valor.nombre}</Text>
                                    </Box>
                                    {!!item.concepto.updated_at && item.concepto.updated_at != '' &&
                                        <Text style={{textAlign: 'right', marginTop: 10, opacity: 0.4}}>{moment(item.concepto.fecha).format('DD/MM/YYYY')}</Text>
                                    }
                                </Box>
                            </Box>
                        )}
                    </Box>)

                })}
            </>
        }

    </Box>);

}