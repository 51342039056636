import React from 'reactn';
import { Switch, Route } from "react-router-dom";

import Tips from '../screens/Tips';
import Eventos from '../screens/Eventos';
import Fotos from '../screens/Fotos';
import Noticias from '../screens/Noticias';
import Acerca from '../screens/Acerca';
import Perfil from '../screens/Perfil';

import Recibidos from '../screens/Recibidos';
import Enviados from '../screens/Enviados';
import Mensaje from '../screens/Mensaje';
import Reporte from '../screens/Reporte';
import NuevoMensaje from '../screens/NuevoMensaje';
import Archivos from '../screens/Archivos';
import SubirArchivo from '../screens/SubirArchivo';
import Comprobantes from '../screens/Comprobantes';

import ActividadesDocentes from '../screens/ActividadesDocentes';
import ActividadesAlumnos from '../screens/ActividadesAlumnos';
import FormActividad from '../screens/FormActividad';
import Actividad from '../screens/Actividad';
import Entregas from '../screens/Entregas';
import Entrega from '../screens/Entrega';
import EnviarEntrega from '../screens/EnviarEntrega';

import Calendario from '../screens/Calendario';
import CalificacionesAlumnos from '../screens/CalificacionesAlumnos';
import CalificacionesDocentes from '../screens/CalificacionesDocentes';
import Concepto from '../screens/Concepto';
import Calificar from '../screens/Calificar';

// import ActividadesGrupos from '../screens/ActividadesGrupos';
// import Actividades from '../screens/_Actividades';
// import ActividadArchivos from '../screens/_ActividadArchivos';
// import ActividadesCalificaciones from '../screens/ActividadesCalificaciones';
// import ActividadesAlumnos from '../screens/ActividadesAlumnos';
// import Calificaciones from '../screens/Calificaciones';


export default class Router extends React.Component{
  render(){
	  return (
	    <Switch>

			<Route path="/eventos" component={Eventos} />
            <Route path="/archivos" component={Archivos} />
			<Route path="/tips" component={Tips} />
			<Route path="/fotos" component={Fotos} />
			<Route path="/acerca" component={Acerca} />
			<Route path="/noticias" component={Noticias} />
			<Route path="/perfil" component={Perfil} />
			
			<Route path="/enviados" component={Enviados} />
			<Route path="/recibidos" component={Recibidos} />
			<Route path="/mensaje" component={Mensaje} />
			<Route path="/reporte" component={Reporte} />
			<Route path="/nuevo-mensaje" component={NuevoMensaje} />

            <Route path="/subir-archivo" component={SubirArchivo} />
			<Route path="/comprobantes" component={Comprobantes} />

            <Route path="/actividades-docentes" component={ActividadesDocentes} />			
            <Route path="/actividades-alumnos" component={ActividadesAlumnos} />			
			<Route path="/form-actividad" component={FormActividad} />
			<Route path="/enviar-entrega" component={EnviarEntrega} />
            <Route path="/actividad/:id" component={Actividad} />
            <Route path="/entregas" component={Entregas} />
            <Route path="/entrega" component={Entrega} />
            <Route path="/calendario" component={Calendario} />

            <Route path="/calificaciones-docentes" component={CalificacionesDocentes} />			
            <Route path="/calificaciones-alumnos" component={CalificacionesAlumnos} />	
            <Route path="/concepto" component={Concepto} />		
            <Route path="/calificar" component={Calificar} />		
            
            {/* <Route path="/actividades-grupos" component={ActividadesGrupos} />
			<Route path="/actividades-alumnos" component={ActividadesAlumnos} />
			<Route path="/actividades" component={Actividades} />
			<Route path="/actividad/:id" component={Actividad} />
			<Route path="/actividad-archivos" component={ActividadArchivos} />
			<Route path="/actividad-calificaciones/:id" component={ActividadesCalificaciones} />
			<Route path="/calificaciones" component={Calificaciones} /> */}


            <Route path="/" component={Recibidos} />

	   </Switch>
	  );
   }
}

