import React, { useState, useEffect } from 'react';
import { Button } from 'bumbag';


export default props => {

	
  	const [imagen, setImagen] = useState(null);

  	const seleccionarArchivo = (e) => {
  		var archivos = [];
  		var files = e.target.files;
  		for (var i = 0; i < files.length; i++) {
  			archivos.push(files.item(i));
  		}
  		if(files.length == 1 && files[0].name.match(/.(jpg|jpeg|png|gif)$/i)){
  			setImagen(URL.createObjectURL(files[0]));
  		}
  		props.onChange(archivos);
  	}

  	var fileSelector;

  	
	fileSelector = document.createElement('input');
	fileSelector.setAttribute('type', 'file');
	fileSelector.setAttribute('multiple', 'multiple');
	fileSelector.onchange = seleccionarArchivo;  		
	

  	var thumb_url = null;
  	if(props.value){
  		thumb_url = props.value;
  	}
  	if(imagen){
  		thumb_url = imagen;
  	}


	return (
		<div>
	    	{!!thumb_url &&
	    		<div style={{marginBottom: 10}}>
	    			<img src={thumb_url} style={{borderRadius: 60, width: 120}} />
	    		</div>
	    	}
	    	<Button onClick={() => fileSelector.click()} marginBottom="2">{props.label ?? 'Seleccionar imagen'}</Button>
	    </div>
  	)

}
