import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Provider as BumbagProvider } from "bumbag";
import { Pane } from "evergreen-ui";
import { BrowserRouter as Router } from "react-router-dom";
import React, { getGlobal, setGlobal } from "reactn";
import initReactnPersist from "reactn-persist";
import "./App.css";
import { messaging } from "./init-fcm";
import Net from "./lib/Net";
import Home from "./screens/Home";
import Login from "./screens/Login";
import { fetchMensajesRecibidos } from "./services/Mensajes";

// estado inicial
setGlobal({
  logueado: false,
  staging: false,
  apiToken: "",
  device: {},
  user: {},
  rol: {},
  recibidos: null,
  mensaje_activo: null,
  enviados: null,
  isConnected: null,
  chats: null,
  pushToken: null,
  notificacionesBloqueadas: null,
  archivosCompartidos: null,
  busquedaRecibidos: "",
  busquedaEnviados: "",

  calificaciones: null,

  readyConceptos: false,
  conceptos: null,
  conceptoActivo: null,
  conceptosFiltroGrupos: "todos",
  conceptosFiltroEC: "todos",

  actividades: null,
  actividadActiva: null,
  readyActividades: true,

  actividadesAlumnos: null, // guarda actividades de los alumnos
  alumnos: null, // guarda los alumnos
  actividadesAlumnoActivo: null, // guarda el alumno activo
  actividadesAlumnosFiltroEspacioCurricular: "todas", // guarda el espacio curricular activo
  readyActividadesAlumnos: true, // marca si está lista la carga
  entregaActiva: null,

  actividadesFiltroGrupos: "todos",
  actividadesFiltroDocentes: "mias",

  readyCalendario: true,
  calendario: null,
  calendarioEventoActivo: null,
  calendarioEventos: [],
  comprobantes: null,
});

// persiste en localstorage
initReactnPersist({
  storage: localStorage,
  key: "@mintor",
  debug: true,
});

const theme = {
  global: {
    fontSize: 14,
  },

  icons: {
    iconSets: [
      {
        icons: Object.values(fas),
        prefix: "solid-",
        type: "font-awesome",
      },
      {
        icons: Object.values(far),
        prefix: "regular-",
        type: "font-awesome",
      },
    ],
  },
};

global.logout = async function () {
  await Net.get("/logout?push_token=" + getGlobal().pushToken);

  setGlobal({
    logueado: false,
    staging: false,
    apiToken: "",
    busquedaRecibidos: "",
    busquedaEnviados: "",
  });

  localStorage.removeItem("@mintor");

  // navigate('/');
  // cogoToast.success('Sesión cerrada correctamente');
};

export default class App extends React.Component {
  async componentDidMount() {
    // el usuario ya está logueado, pedimos permiso para notificar
    if (messaging) {
      // el navegador soporta firebase
      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken();
          if (token) {
            this.setGlobal({
              pushToken: token,
              notificacionesBloqueadas: false,
            });
          } else {
            console.log("no se pudo obtener el token");
          }
        })
        .catch((err) => {
          this.setGlobal({ notificacionesBloqueadas: true });
          console.log("notificaciones bloqueadas:");
        });
      // recibir los mensajes del service worker
      navigator.serviceWorker.addEventListener("message", (message) => {
        fetchMensajesRecibidos();
      });
      const channel = new BroadcastChannel("sw-messages");
      channel.addEventListener("message", (event) => {
        fetchMensajesRecibidos();
      });
    }
  }

  render() {
    /*
  		if(this.global.notificacionesBloqueadas === null || this.global.notificacionesBloqueadas === true){
  			return (
  					<BumbagProvider theme={theme}>	
  						<NotificacionesBloqueadas />
  					</BumbagProvider>
  				)
  		}
		*/

    return (
      <BumbagProvider theme={theme}>
        <Pane
          display="flex"
          height={window.innerHeight}
          justifyContent="flex-start"
          flexDirection="column"
          alignItems={"stretch"}
          alignContent={"stretch"}
        >
          {!this.global.logueado && <Login />}

          {this.global.logueado && (
            <Router>
              <Home />
            </Router>
          )}
        </Pane>
      </BumbagProvider>
    );
  }
}
