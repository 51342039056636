import React, { useEffect, useGlobal } from 'reactn'
import { Box } from 'bumbag';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import { fetchCalendario } from '../services/Calendario';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default function Calendar(props){

    const [calendarioEventos] = useGlobal('calendarioEventos');

    const init = async () => {
        await fetchCalendario();
    }

    useEffect(() => {
        init();
    }, []);

    const eventDidMount = (info) => {
        tippy(info.el, {
            content: info.event._def.title,
        });
    }
    
    return (
        <Box>
            <FullCalendar
            locale={esLocale}
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            events={calendarioEventos}
            eventDidMount={eventDidMount}
            
            />
        </Box>
    );

}