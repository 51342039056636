import { getGlobal, setGlobal } from 'reactn';
import Net from '../lib/Net';

export async function fetchCalendario(filtro){

    var tipo = 'todos';
    if(typeof filtro != 'undefined') tipo = filtro;

    await setGlobal({ readyCalendario: false });
    
    var data = await Net.get('/calendario?agrupar_por_dia=1&tipo=' + tipo);
    var calendarioEventos = [];
    for (const [dia, eventos] of Object.entries(data.result)) {
        var event_date = dia.substring(0, 10);
        for(var e of eventos){
            calendarioEventos.push({title: e.titulo, date: event_date});
        }
    }

    // organiza los eventos del calendario por id,
    // para pasar a las vistas de detalle y edicion la referencia
    // y poder reflejar los cambios en vivo
    await setGlobal({ calendarioEventos, readyCalendario: true, calendario: data.result});
}

// busca un evento por id en el globalstorage y lo retorna
export function buscarPorIdEnGlobal(id){
    var calendario = getGlobal().calendario;
    for(var [dia, eventos] of Object.entries(calendario)){
        for(var evento of eventos){
            if(evento.id === id) return evento;
        }
    }
    return null;
}