import React from 'reactn';
import '../App.css';
import cogoToast from 'cogo-toast';
import Net from '../lib/Net';
import { validarEmail } from '../lib/Helpers';
import { Button, Input, Container, Box } from 'bumbag';
import logo from '../assets/logo.png';

export default class Login extends React.Component{

	constructor(props){
        super(props)
        this.state = {
            busyLogin: false,
            busyRecovery: false,
            email: '',
            password: '',
            ready: false,
            authorized: false,
            passwordRecoveryScreen: false,
            recoveryEmail: ''
        }
    }

  	
  	async componentDidMount(){
        this.setState({ready: true});
    }


  	async login(){

  		if(this.state.busyLogin) return false;

  		var email = this.state.email;
        var password = this.state.password;


        if(email === ''){
        	cogoToast.error("El email no es válido");
            return false;
        }

        if(password === ''){
        	cogoToast.error("Email o contraseña incorrecta");
            return false;
        }

        if(email.indexOf('-staging') > -1){
        	// staging activado
        	email = email.replace('-staging', '');
        	await this.setGlobal({ staging: true});
        }

        this.setState({ busyLogin: true });
        
        var params = {
            email: email,
            password: password
        }
        
        var data = await Net.post('/login-generica', params);
        
        this.setState({ busyLogin: false });

        if(data.status !== 'success'){
        	cogoToast.error("Email o contraseña incorrecta");
        	await this.setGlobal({ staging: false});
            return false;
        }

    	// guardar en globalstate
    	let user = {
        	id: data.user_id,
        	email: data.email,
        	nombre: data.nombre,
        	apellido: data.apellido,
        	roles: data.roles,
        	telefono: data.telefono
        };

        await this.setGlobal({
            user: user,
            rol: data.roles[0],
            apiToken: data.token,
            logueado: true,
            recibidos: null,
            enviados: null,
            archivosCompartidos: null
        });

        
  	}

  	async passwordRecovery(){
        if(this.state.busyRecovery) return false

        if(this.state.recoveryEmail === '' || !validarEmail(this.state.recoveryEmail)){
        	cogoToast.error("Ingresar una dirección de email válida");
            return false
        }

        this.setState({ busyRecovery: true })

        let params = {
            email: this.state.recoveryEmail,
        }

        var data = await Net.post('/recuperar-clave', params)
        
        this.setState({ busyRecovery: false });
        if(data.status === 'error'){
            cogoToast.error(data.message);
            return false;
        }
        cogoToast.success('Hemos enviado una nueva contraseña a tu email');
        this.setState({ passwordRecoveryScreen: false });
    }
  
  	render(){
  		return (
  			<Container breakpoint="mobile" marginTop="major-2" padding="major-4">

  				<p style={{textAlign: 'center', margin: 0}}>
  					<img src={logo} style={{width: 100, marginBottom: 40}} />
  				</p>

		    	<Box borderRadius="4" padding="major-4" backgroundColor="#fff" style={{border: '1px solid #ebeff6'}}>

		  			<Box marginTop="major-2">
		  				<Input type={'email'} marginBottom={3} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} placeholder="Email" />
			  		</Box>

			  		<Box marginTop="major-2">
			  			<Input marginBottom={3} type="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} placeholder="Contraseña" />
					</Box>

					<Box  marginTop="major-2" style={{textAlign: 'right', margin: 0}}>
						<Button palette={'primary'} isLoading={this.state.busyLogin} onClick={() => this.login()}>Acceder</Button>
					</Box>
					
				</Box>
			</Container>
		)
  	}
}

