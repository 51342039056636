import React, { useState } from 'react';
import { Button, Tag, Input, Overlay, Box, Card, Group } from 'bumbag';
import cogoToast from 'cogo-toast';
import Net from '../lib/Net';


export default props => {

	const [modalGuardarAbierto, setModalGuardarAbierto] = useState(false);
	const [modalSeleccionarAbierto, setModalSeleccionarAbierto] = useState(false);
	const [nombre, setNombre] = useState('');
	const [mensajes, setMensajes] = useState([]);

    const overlayGuardar = Overlay.useState();
    const overlayGuardarProps = Overlay.useProps({ ...overlayGuardar });

    const overlaySeleccionar = Overlay.useState();
    const overlaySeleccionarProps = Overlay.useProps({ ...overlaySeleccionar });

	const guardarPlantilla = async () => {
		var mensaje = document.getElementById('mensaje').value;
		if(mensaje === ''){
			cogoToast.error('Escribir un mensaje');
			return false;
		}
		if(nombre === ''){
			cogoToast.error('Escribir un nombre');
			return false;
		}
		const params = {
			nombre: nombre,
    		contenido: mensaje
		}
		await Net.post('/plantillas', params);

        overlayGuardar.setVisible(false);    
		
	}

	const cargarMensajesGuardados = async () => {
		if(mensajes.length === 0){
			var data = await Net.get('/plantillas');
			setMensajes(data.result);
		}
		overlaySeleccionar.setVisible(true);
	}


	const eliminarMensaje = async (id) => {
		await Net.post('/plantillas/' + id + '/eliminar');
		var index = -1;
		var m = [...mensajes];
		m.map((mensaje, i) => {
			if(mensaje.id === id) index = i;
		});
		m.splice(index, 1);
		setMensajes(m);
	}

	return (
		<React.Fragment>
            
            <Box {...overlayGuardarProps}>
                <Card width="400px" title={'Guardar mensaje'}>	
                    <div>Podés guardar un mensaje para poder seleccionarlo y volver a utilizarlo después</div>
                    <Group marginTop="major-5">
                        <Input placeholder={'Nombre del mensaje'} onChange={e =>  setNombre(e.target.value)} />
                        <Button onClick={() => guardarPlantilla()} palette={'primary'}>Guardar</Button>
                    </Group>
                </Card>
            </Box>
            
            <Box {...overlaySeleccionarProps}>
                <Card width="400px" title={'Seleccionar un mensaje guardado'}>	
                    {mensajes.length === 0 &&
                        <div>No hay mensajes guardados</div>
                    }
                    {mensajes.length > 0 &&
                        <div>
                            <div>Seleccioná un mensaje guardado para volver a utilizarlo</div>
                            <Box marginTop="major-2">
                                {mensajes.map((mensaje, i) => 
                                    (
                                        <Tag key={i} kind="outlined" cursor={'pointer'} size="medium" palette="primary" marginRight={'major-1'} marginBottom={'major-1'} onClick={() => {props.onSelected(mensaje.contenido); setModalSeleccionarAbierto(false);}}  onRemove={e => {e.stopPropagation(); eliminarMensaje(mensaje.id);}}>{mensaje.nombre}</Tag>
                                    )
                                )}
                            </Box>
                        </div>
                    }
                </Card>
            </Box>
        
			<Button size="small" style={{marginLeft: 8}} onClick={() => overlayGuardar.setVisible(true)}>Guardar mensaje</Button>
			<Button size="small" style={{marginLeft: 8}} onClick={() => cargarMensajesGuardados()}>Cargar mensaje</Button>
	      	
		</React.Fragment>
	);
                                        	
}