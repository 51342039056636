import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import addReactNDevTools from 'reactn-devtools';
import { fetchMensajesRecibidos } from './services/Mensajes';
window.fetchMensajesRecibidos = fetchMensajesRecibidos;

addReactNDevTools();

if ("serviceWorker" in navigator) {
  	navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      	console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
    });
    navigator.serviceWorker.addEventListener("push", (message) => console.log('Received a push message 2:', message));
}else{
	console.log('navegador no soportado');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
