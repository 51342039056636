import { Menu } from 'bumbag';
import { Icon } from 'evergreen-ui';
import { useHistory } from "react-router-dom";
import React, { getGlobal } from 'reactn';
import Andromeda from '../lib/Andromeda';

export default function MainMenuOptions(){
  
	var history = useHistory();
	
	function navigate(path) {
    	history.push(path);
  	}

	var modulo_gestion_academica = Andromeda.tieneModulo('gestion_academica'); 
	var modulo_gestion_administrativa = Andromeda.tieneModulo('gestion_administrativa');  	

	var rol_activo = getGlobal().rol;

	

	return (
		
	    <Menu>
	      <Menu.Group>

			<Menu.Item onClick={async () => { if(global.limpiarBusqueda) global.limpiarBusqueda(); navigate('/comunicaciones');}}><Icon icon="envelope" marginRight="10px" size={16} color={'#AAA'} />Comunicaciones</Menu.Item>
			
			{!modulo_gestion_academica &&
			    <Menu.Item onClick={() => navigate('/archivos')}><Icon icon="document" marginRight="10px" size={16} color={'#AAA'} />Archivos</Menu.Item>
			}

			{Andromeda.userEsTutor() && modulo_gestion_academica && ( rol_activo.users_roles_cliente_id == 33 || rol_activo.users_roles_cliente_id == 34 || rol_activo.users_roles_cliente_id == 29 ) &&
			    
			    <Menu.Item onClick={() => navigate('/archivos')}><Icon icon="document" marginRight="10px" size={16} color={'#AAA'} />Archivos</Menu.Item>
			    
			}

			{Andromeda.userEsTutor() && modulo_gestion_administrativa && ( rol_activo.users_roles_cliente_id == 26 || rol_activo.users_roles_cliente_id == 30 || rol_activo.users_roles_cliente_id == 33 || rol_activo.users_roles_cliente_id == 34 ) &&
			    
			    <Menu.Item onClick={() => navigate('/comprobantes')}><Icon icon="import" marginRight="10px" size={16} color={'#AAA'} />Comprobantes</Menu.Item>
			    
			}

            {Andromeda.userEsDocente() && modulo_gestion_academica &&
                <>
                <Menu.Item onClick={() => navigate('/actividades-docentes')}><Icon icon="edit" marginRight="10px" size={16} color={'#AAA'} />Actividades</Menu.Item>
                <Menu.Item onClick={() => navigate('/calendario')}><Icon icon="calendar" marginRight="10px" size={16} color={'#AAA'} />Calendario</Menu.Item>
                <Menu.Item onClick={() => navigate('/calificaciones-docentes')}><Icon icon="tick" marginRight="10px" size={16} color={'#AAA'} />Calificaciones</Menu.Item>
                </>
            }

            {(Andromeda.userEsTutor() || Andromeda.userEsAlumnoIndependiente()) && modulo_gestion_academica &&
                <>
                <Menu.Item onClick={() => navigate('/actividades-alumnos')}><Icon icon="edit" marginRight="10px" size={16} color={'#AAA'} />Actividades</Menu.Item>
                <Menu.Item onClick={() => navigate('/calendario')}><Icon icon="calendar" marginRight="10px" size={16} color={'#AAA'} />Calendario</Menu.Item>
                <Menu.Item onClick={() => navigate('/calificaciones-alumnos')}><Icon icon="tick" marginRight="10px" size={16} color={'#AAA'} />Calificaciones</Menu.Item>
                </>
            }

			
			{/*<Menu.Item icon="regular-file" onClick={() => navigate('/calificaciones-grupos')}>Calificaciones</Menu.Item>*/}
			{/*<Menu.Item icon="regular-file" onClick={() => navigate('/classroom')}>Classroom</Menu.Item>*/}
			
			{/*<Menu.Item icon="regular-calendar" onClick={() => navigate('/eventos')}>Eventos</Menu.Item>*/}
			{/*<Menu.Item icon="regular-lightbulb" onClick={() => navigate('/tips')}>Tips</Menu.Item>*/}
			{/*<Menu.Item icon="regular-images" onClick={() => navigate('/fotos')}>Fotos</Menu.Item>*/}
			{/*<Menu.Item icon="regular-newspaper" onClick={() => navigate('/noticias')}>Noticias</Menu.Item>*/}
			
			<Menu.Item onClick={() => navigate('/perfil')}><Icon icon="user" marginRight="10px" size={16} color={'#AAA'} />Perfil</Menu.Item>
			<Menu.Item onClick={() => global.logout()}><Icon icon="unlock" marginRight="10px" size={16} color={'#AAA'} />Salir</Menu.Item>
	      </Menu.Group>
	    </Menu>
    
	);

}

