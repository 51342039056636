import { Box, Button, Heading, InputField, Label, Select, Switch, Tag, Textarea, Flex } from 'bumbag';
import _ from 'lodash';
import moment from 'moment';
import React from 'reactn';
import DateTimePicker from '../components/DateTimePicker';
import FilePicker from '../components/FilePicker';
import SelectMultiple from '../components/SelectMultiple';
import Andromeda from '../lib/Andromeda';
import Net from '../lib/Net';
import { Icon, toaster } from 'evergreen-ui';
import { fetchActividadesDocentes } from '../services/Actividades';


export default class FormActividad extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            editando: props.location.state ? props.location.state.editando : false,
            nombre: '',
            calificable: false,
            calif_impacta_nota_final: false,
            tiene_calificaciones: false,
            fecha_inicio: '',
            fecha_entrega: '',
            escalas: null,
            grupos: [],
            grupo_id: '',
            alumnos: [],
            alumnosSeleccionados: [],
            escalasSeleccionadas: [],
            espaciosCurriculares: null,
            alumnosActividad: [],
            espacioCurricular: '',
            descripcion: '',
            archivos: [],
            fotos: [],
            ready: false,
            loadingBorrador: false,
            loadingGuardar: false,
        }
    }

    async fetchAlumnos(grupo_id, todos_seleccionados = true){
        var data = await Net.get('/alumnos?grupo_id=' + grupo_id);
        if(data.result.length > 0){
            var alumnos = data.result.map(a => ({id: a.id.toString(), name: a.apellido + ' ' + a.nombre}));
            if(todos_seleccionados)
                this.setState({alumnos: alumnos, alumnosSeleccionados: alumnos});
            else
                this.setState({alumnos: alumnos});
        }else{
            toaster.danger('El grupo seleccionado no contiene alumnos, por favor selecciona otro grupo');
            this.setState({ grupo_id: '', espaciosCurriculares: [], espacioCurricular: ''});
        }
    }

    async fetchEspaciosCurriculares(grupo_id){
        var data = await Net.get('/espacios-curriculares?grupo_id=' + grupo_id);
        if(data.result.length > 0){
            this.setState({espaciosCurriculares: data.result.map(a => ({value: a.espacio_curricular.id.toString(), label: a.espacio_curricular.nombre}))});
        }
    }

    async fetchData(){
        
        // recupera del server escalas y espacios curriculares
        var data = await Net.get('/escalas');
        this.setState({escalas: data.result.map(a => ({id: a.id.toString(), name: a.nombre}))});

        var data = await Net.get('/grupos');
        this.setState({
            grupos: data.result.map(g => ({value: g.id.toString(), label: g.nombre}))
        });

    }

    async componentDidMount(){    
        
        var editando = this.props.location.state ? this.props.location.state.editando : false;

        
        await this.fetchData();


        if(editando){

            var actividad = this.global.actividadActiva;

            var data = await Net.get('/actividades/' + actividad.id + '/alumnos');
            var alumnosActividad = data.result;

            // recupera alumnos del grupo de la actividad activa
            await this.fetchAlumnos(actividad.grupo_id, false);
            
            var tiene_calificaciones = false;
            var alumnosSeleccionados = [];
            
            for(var alumno of alumnosActividad){
                alumnosSeleccionados.push({id: alumno.id.toString(), name: alumno.apellido + ' ' + alumno.nombre});
                if(alumno.pivot.fecha_calificacion != '' && alumno.pivot.fecha_calificacion != null) tiene_calificaciones = true;
            }
            
            // recupera espacios curriculares del grupo de la actividad activa
            await this.fetchEspaciosCurriculares(actividad.grupo_id);


            console.log(this.global.actividadActiva);

            this.setState({
                editando: true,
                ready: true,
                grupo_id: actividad.grupo_id.toString(),
                alumnosActividad: alumnosActividad,
                alumnosSeleccionados: alumnosSeleccionados,
                nombre: actividad.nombre,
                descripcion: actividad.descripcion,
                calificable: actividad.calificable == 1,
                calif_impacta_nota_final: actividad.calif_impacta_nota_final == 1,
                tiene_calificaciones: tiene_calificaciones,
                fecha_inicio: actividad.fecha_inicio,
                fecha_entrega: actividad.fecha_entrega,
                escalasSeleccionadas: (actividad.calificable == 1 && actividad.concepto) ? [{id: actividad.concepto.escala.id, name: actividad.concepto.escala.nombre}] : [],
                espacioCurricular: actividad.espacio_curricular ? actividad.espacio_curricular.id : '',
            });

        }
        
    }

    addAlumno = alumno => {
		var alumnosSeleccionados = this.state.alumnosSeleccionados;
		alumnosSeleccionados.push(alumno);
		this.setState({ alumnosSeleccionados });
    };

    seleccionarTodosAlumnos = () => {
        this.setState({ alumnosSeleccionados: this.state.alumnos });
    }

    deSeleccionarTodosAlumnos = () => {
        this.setState({ alumnosSeleccionados: [] });
    }
    
    deleteAlumno = index => {
		var alumnosSeleccionados = [...this.state.alumnosSeleccionados];
		alumnosSeleccionados.splice(index, 1);
		this.setState({ alumnosSeleccionados });
	};

    seleccionarEscala = (escala) => {
        this.setState({ escalasSeleccionadas: [escala]});
    }

    deseleccionarEscala = () => {
        this.setState({ escalasSeleccionadas: []});
    }

    async enviarArchivo(archivo, url){
        let fd = new FormData();
        fd.append('archivo', archivo);
        var data = await Net.post(url, fd, true, true);
        return data.result.id;
    }

    async submit(borrador = false){
        
        if(this.state.loadingBorrador || this.state.loadingGuardar) return false;

        if(this.state.grupo_id == ''){
            toaster.danger('Por favor seleccionar un grupo');
            return false;
        }

        if(this.state.espaciosCurriculares && this.state.espaciosCurriculares.length > 0 && this.state.espacioCurricular == ''){
            toaster.danger('Por favor seleccionar un espacio curricular');
            return false;
        }

        if(this.state.nombre == ''){
            toaster.danger('Por favor ingresar un nombre para la actividad');
            return false;
        }

        if(this.state.calificable && this.state.escalasSeleccionadas.length == 0){
            toaster.danger('Por favor seleccionar una escala de calificación');
            return false;
        }

        if(!borrador){

            if(this.state.alumnos.length == 0){
                toaster.danger('El grupo seleccionado no contiene alumnos');
                return false;
            }

            if(this.state.alumnosSeleccionados.length == 0){
                toaster.danger('Por favor seleccionar alumnos para crear la actividad');
                return false;
            }

            if(!this.state.fecha_inicio || this.state.fecha_inicio == ''){
                toaster.danger('Por favor seleccionar una fecha de inicio');
                return false;
            }

            if(!this.state.fecha_entrega || this.state.fecha_entrega == ''){
                toaster.danger('Por favor seleccionar una fecha de entrega');
                return false;
            }

            if(this.state.fecha_inicio && !this.state.fecha_inicio.includes(':')){
                toaster.danger('Por favor seleccionar una hora de inicio');
                return false;
            }

            if(this.state.fecha_entrega && !this.state.fecha_entrega.includes(':')){
                toaster.danger('Por favor seleccionar una hora de entrega');
                return false;
            }

            if(this.state.fecha_inicio > this.state.fecha_entrega){
                toaster.danger('La fecha de inicio debe ser anterior a la fecha de entrega');
                return false;
            }

            this.setState({ loadingGuardar: true });

        }else{

            this.setState({ loadingBorrador: true });

        }
        
        let form_data = new FormData();

        form_data.append('grupo_id', this.state.grupo_id);        

        var alumnos = [];
        this.state.alumnosSeleccionados.map((alumno) => {
            alumnos.push(alumno.id)
        });

        form_data.append('alumnos_id', alumnos.join());        
        form_data.append('nombre', this.state.nombre);
        form_data.append('descripcion', this.state.descripcion ?? '');

        if(this.state.calificable){
            if(this.state.escalasSeleccionadas.length > 0)
                form_data.append('calificacion_escala_id', this.state.escalasSeleccionadas[0].id);
        }

        if(this.state.espaciosCurriculares && this.state.espaciosCurriculares.length > 0){
            form_data.append('espacio_curricular_id', this.state.espacioCurricular);
        }

        var fecha_inicio = this.state.fecha_inicio;
        var fecha_entrega = this.state.fecha_entrega;

        // formatear fechas
        if(fecha_inicio && fecha_inicio.includes('/')){
            fecha_inicio = moment(fecha_inicio, "DD/MM/YYYY H:m").format('YYYY-MM-DD H:m');
        }

        if(fecha_inicio && fecha_entrega.includes('/')){
            fecha_entrega = moment(fecha_entrega, "DD/MM/YYYY H:m").format('YYYY-MM-DD H:m');
        }

        form_data.append('fecha_inicio', this.state.fecha_inicio);
        form_data.append('fecha_entrega', this.state.fecha_entrega);
        form_data.append('calificable', this.state.calificable);
        form_data.append('calif_impacta_nota_final', this.state.calif_impacta_nota_final);
        if(borrador) form_data.append('borrador', true);
        
        var url_archivos = '/actividades-archivos?user_id=' + this.global.user.id;
        var url_guardar = '/actividades';

        if(this.state.editando){
            url_archivos = '/actividades-archivos?user_id=' + this.global.user.id + '&actividad_id=' + this.global.actividadActiva.id;
            url_guardar = '/actividades/' + this.global.actividadActiva.id;
        }
        
        var archivos_ids = [];
        var promises_archivos = this.state.archivos.map(async (archivo) => {
            var archivo_id = await this.enviarArchivo(archivo, url_archivos);
            archivos_ids.push(archivo_id);
        });

        await Promise.all(promises_archivos);
        form_data.append('archivos_ids', archivos_ids.join());

        var data = await Net.post(url_guardar, form_data, true);

        if(borrador){
            toaster.success('El borrador se ha guardado correctamente');
        }else{
            if(data.aprobada == 1){
                toaster.success('La actividad se ha guardado correctamente');
            }else{
                toaster.notify('La actividad se ha guardado, pero coordinación debe aprobarla para que sea visible por los alumnos', { duration: 5 });
            }
        }

        console.log('form actividad submit');
        fetchActividadesDocentes(true);

        this.setState({ loadingBorrador: false, loadingGuardar: false });

        if(this.state.editando){
            this.props.history.goBack();
        }else{
            this.props.history.push('/actividades-docentes');
        }

    }

    quitarArchivo(quitar){
    	console.log('quitar');
    	var archivos = this.state.archivos.slice();
    	var indice = -1;
    	archivos.map((a, i) => {
    		if(a.name === quitar.name) indice = i;
    	});
    	if(indice > -1){
    		archivos.splice(indice, 1);
    		this.setState({archivos});
    	}
    }

    async eliminarArchivo(archivo_id){

        if(window.confirm('¿Seguro que deseas eliminar este archivo?')){
            await Net.get('/actividades-archivos/' + archivo_id + '/eliminar');
            await fetchActividadesDocentes(true);
        }
        
    }

    async seleccionarGrupo(grupo_id){
        
        this.setState({grupo_id: grupo_id, espaciosCurriculares: [], alumnos: []});
        if(grupo_id == '') return;
        await this.fetchEspaciosCurriculares(grupo_id);
        await this.fetchAlumnos(grupo_id);
        this.setState({ready: true});

    }

    adjuntarArchivos(files){
    	var archivos = this.state.archivos.slice();
    	files.map(file => {
    		var agregar = true;
	    	archivos.map(archivo => {
	    		if(archivo.name === file.name) agregar = false;
	    	});
	    	if(agregar) archivos.push(file);
    	});
	    this.setState({archivos});
    }


    render() {
        
        console.log(this.props);

        var editando = this.props.params ? this.props.params.editando : false;
        var actividad = this.global.actividadActiva;
        
        if(editando && !this.state.ready) return <Box style={{backgroundColor: '#fff', flex: 1, padding: 20}}>cargando...</Box>;

        var archivos_docente = [];
        if(actividad && actividad.archivos){
            actividad.archivos.map((archivo, i) => {
                if(archivo.user_id == actividad.docente_id) archivos_docente.push(archivo);
            });
        }

        return (

            <Box maxWidth="600px" widht="100%">

                <Box>
                    <Button iconBefore="solid-arrow-left" variant="ghost" onClick={() => this.props.history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
                </Box>
            
                <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">{!this.state.editando ? 'Crear una actividad' : 'Editando actividad'}</Heading>
                
                {this.state.editando &&
                    <Box style={{ marginBottom: 10 }}>Si se modifican las opciones de calificación se borrarán todas las calificaciones existentes</Box>
                }

                <Select
                options={[{value: '', label: 'Seleccionar grupo'}, ...this.state.grupos]}                      
                value={this.state.grupo_id}
                onChange={e => this.seleccionarGrupo(e.target.value)}
                />
                
                {this.state.ready &&
                    <Box>

                        {this.state.alumnos.length > 0 &&
                            <Box marginTop="major-4">
 
                                <SelectMultiple
                                options={this.state.alumnos}
                                selected={this.state.alumnosSeleccionados}
                                txt_button={'Seleccionar alumnos'}
                                txt_placeholder={'Buscar alumnos...'}
                                txt_empty={'No se encontraron alumnos'}
                                btn_seleccionar_todos={true}
                                handleAddition={this.addAlumno}
                                handleSelectAll={this.seleccionarTodosAlumnos}
                                handleUnSelectAll={this.deSeleccionarTodosAlumnos}
                                txt_seleccionados={['alumno seleccionado', 'alumnos seleccionados']}
                                seleccionados_en_boton={true}
                                handleDelete={this.deleteAlumno} />
                            
                            </Box>
                        }

                        {this.state.espaciosCurriculares && this.state.espaciosCurriculares.length > 0 &&
                            <Box marginTop="major-4">

                                <Select
                                options={[{value: '', label: 'Seleccionar espacio curricular'}, ...this.state.espaciosCurriculares]}                      
                                value={this.state.espacioCurricular}
                                onChange={e => this.setState({espacioCurricular: e.target.value})}
                                />

                            </Box>
                        }

                        
                        <InputField marginTop="major-4" label="Nombre de la actividad" onChange={e => this.setState({nombre: e.target.value})} value={this.state.nombre} />
                        <Box marginTop="major-4">
                            <DateTimePicker labelFecha="Fecha de inicio" labelHora="Hora de inicio" value={ this.state.fecha_inicio } onChange={fecha_inicio => this.setState({fecha_inicio})} />
                            <Box style={{fontSize: 11, marginTop: 2, opacity: 0.4}}>Esta es la fecha en que la actividad será visible en la app y estará disponible para los alumnos</Box>
                        </Box>
                        
                        <Box marginTop="major-4">
                            <DateTimePicker labelFecha="Fecha de entrega" labelHora="Hora de entrega" value={ this.state.fecha_entrega } onChange={fecha_entrega => this.setState({fecha_entrega})} /> 
                            <Box style={{fontSize: 11, marginTop: 2, opacity: 0.4}}>Está es la fecha y hora límite para que los alumnos completen la actividad y envien sus archivos</Box>
                        </Box>
                        
                        <Label marginTop="major-4" marginBottom="major-2">Descripción para los alumnos</Label>
                        <Textarea
                        rows="10"       
                        onChange={e => this.setState({descripcion: e.target.value})}
                        value={this.state.descripcion} />

                        {this.state.editando && archivos_docente.length > 0 &&
                            <Box>
                                {archivos_docente.map((archivo, i) => {
                                    return (
                                        <div className="archivo-editar">
                                            <Flex>  
                                                <a href={archivo.url} className="archivo-editar" target="_blank" key={i}>
                                                    <Flex>  
                                                        <Icon icon="paperclip" size={18} color={'#AAA'} />
                                                        <Box style={{ marginLeft: 10, fontWeight: 'bold' }}>{_.truncate(archivo.nombre, {length: 25})}</Box>
                                                    </Flex>
                                                </a>
                                                {Andromeda.userEsDocente() && archivo.user_id == this.global.user.id &&
                                                    <a className="archivo-editar-eliminar hover-opacity" onClick={() => this.eliminarArchivo(archivo.id)} key={i}>
                                                        <Icon icon="cross" size={15} color={'#AAA'} />
                                                    </a>
                                                }
                                            </Flex>
                                        </div>
                                    )}
                                )}
                            </Box>
                        }
                        
                        <Box style={{marginTop: 10}}>
                            <FilePicker onChange={files => this.adjuntarArchivos(files)} />
                            <Box marginTop="major-2">
                                {this.state.archivos.length > 0 && 
                                    <div>
                                        {this.state.archivos.map((a, i) => {
                                            return (<Tag palette="secondary" style={{marginRight: 5}} key={i} onRemove={() => this.quitarArchivo(a)}>{a.name}</Tag>);
                                        })}
                                    </div>
                                }
                            </Box>
                        </Box>

                        {this.state.tiene_calificaciones && 
                            <Box style={{backgroundColor: '#E0E7FF', marginTop: 20, padding: 20, borderRadius: 10}}>
                                <Box>Esta actividad tiene calificaciones guardadas. Para modificar los criterios de evaluación es necesario eliminar las calificaciones primero.</Box>
                            </Box>
                        }
                        
                        {!this.state.tiene_calificaciones && 
                            <Box marginTop="major-4">

                                <Switch label="Esta actividad se califica" onChange={() => this.setState({calificable: !this.state.calificable})} checked={this.state.calificable} />

                                {this.state.calificable &&
                                    <Box>
                                        {this.state.escalas && this.state.escalas.length > 0 &&
                                            <Box marginTop="major-2">
                                                
                                                <SelectMultiple
                                                style={{zIndex: 2, marginTop: 5}}
                                                options={this.state.escalas}
                                                selected={this.state.escalasSeleccionadas}
                                                txt_button={'Seleccionar escala de calificación'}
                                                txt_empty={'No se encontraron escalas'}
                                                handleAddition={this.seleccionarEscala}
                                                handleDelete={this.deseleccionarEscala} />
                                            </Box>
                                        }

                                        <Switch marginTop="major-2" label="La calificación afecta la nota final" onChange={() => this.setState({calif_impacta_nota_final: !this.state.calif_impacta_nota_final})} checked={this.state.calif_impacta_nota_final} />
                                    </Box>
                                }

                            </Box>
                        }

                        <Box marginTop="major-4" marginBottom="major-8">
                            {(!this.state.editando || actividad.borrador == 1) &&
                                <Button marginRight="major-2" isLoading={this.state.loadingBorrador} onClick={() => this.submit(true)} styleButton={{backgroundColor:'#E0E2E1', marginTop: 40}} styleText={{color:'#666'}}>Guardar borrador</Button>
                            }
                            <Button isLoading={this.state.loadingGuardar} onClick={() => this.submit()}>{this.state.editando && !actividad.borrador ? 'Guardar actividad' : 'Crear actividad'}</Button>
                        </Box>
                    
                    </Box>
                }
            
               
            </Box>

        );

    }

}

