import { Box, Icon, Text, Select, Button, Flex } from 'bumbag';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import React from 'reactn';
import Net from '../lib/Net';
import moment from 'moment';
import { fetchActividadesAlumnos } from '../services/Actividades';

export default class ActividadesAlumnosScreen extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loadingMore: false,
            allLoaded: false,
            grupos: [],
        }
    }

    async componentDidMount(){  
        
        // recupera alumnos para armar el select
        
        var data = await Net.get('/actividades-alumnos/alumnos');
        
        if(data.length == 0){
            alert('Alumno no válido');
            this.props.history.goBack();
            return false;
        }

        await this.setGlobal({alumnos: data});
        
        // marca el 1er alumno como activo
        await this.setGlobal({actividadesAlumnoActivo: data[0]});

        // recupera actividades
        await fetchActividadesAlumnos();   
    
    }


    render() {



        if(!this.global.alumnos) return ( <Box><Text>cargando...</Text></Box>);

        
        // segurarse que el filtro seleccionado no tiene un valor incorrecto

        return (
            <Box style={{maxWidth: 700, padding: 20, flex: 1, flexDirection: 'column', backgroundColor: '#fff'}}>
            
                <Flex justifyContent="space-between" marginBottom={'major-2'}>
                    <Flex>
                        <Box>
                            {this.global.alumnos.length >= 2 &&

                                <Select
                                options={this.global.alumnos.map(a => ({value: a.id.toString(), label: a.apellido + ' ' + a.nombre}))}
                                placeholder={'Filtrar por alumno...'}                            
                                value={this.global.actividadesAlumnoActivo ? this.global.actividadesAlumnoActivo.id.toString() : ''}
                                onChange={async e => {
                                        await this.setGlobal({ actividadesAlumnos: null, actividadesAlumnoActivo: this.global.alumnos.find(a => a.id.toString() == e.target.value), actividadesAlumnosFiltroEspacioCurricular: 'todas'})
                                        fetchActividadesAlumnos(true);
                                    }}
                                />
                                
                            }
                        </Box>
                    </Flex>
                </Flex>
                
                {!this.global.actividadesAlumnos &&
                    <Box><Text>cargando...</Text></Box>
                }

                {this.global.actividadesAlumnos && this.global.actividadesAlumnos.length > 0 &&
                    
                    <div>
                    
                        {this.global.actividadesAlumnos.map((actividad, i) => {

                            var className = 'listaItem';
                            if(actividad.borrador == 1) className += ' borrador';



                            return (

                                <Link key={i} style={{minHeight: 60, flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: '#EEEEEE', paddingVertical: 10}}
                                onClick={() => {  this.setGlobal({actividadActiva: actividad}); }} to={'/actividad/' + actividad.id}>
                                    
                                    <div className={className}>
                                    
                                        <Box style={{paddingRight: 60}}>
                                            
                                            <Box style={{flexDirection: 'row', marginBottom: 5}}>
                                                <Text style={{fontWeight: 'bold'}}>{_.truncate(actividad.nombre, {length: 50})}</Text>
                                            </Box>

                                            {!!actividad.docente && actividad.docente_id != this.global.user.id &&
                                                <Text style={{marginBottom: 5, opacity: 0.5, fontSize: 12}}>{actividad.docente.nombre + ' ' + actividad.docente.apellido}
                                                {!!actividad.espacio_curricular && <Text>, {_.truncate(actividad.espacio_curricular.nombre)}</Text>}</Text>
                                            }
                                            
                                            {!!actividad.grupo &&
                                                <div className="pastilla" style={{marginLeft: 5, borderColor: '#aaa', color: '#aaa'}}>{actividad.grupo.nombre}</div>
                                            }
                                            
                                        </Box>
                                        
                                        {actividad.calificacion_cerrada == 1 &&
                                            <Box style={{position: 'absolute', top: 40, right: 10}}>
                                                <Icon aria-label="Settings" color={'#4dd966'} icon="solid-check" />
                                            </Box>
                                        }
                                        
                                        {!!actividad.fecha_inicio && 
                                            <Box style={{opacity: 0.5, position: 'absolute', top: 10, right: 10}}>
                                                <Box>{ moment(actividad.fecha_inicio).format('DD/MM/YYYY') }</Box>
                                            </Box>
                                        }

                                        {!!actividad.entregas && actividad.entregas.filter(ent => ent.alumno_id == this.global.actividadesAlumnoActivo.id).length > 0 &&
                                            <Icon style={{position: 'absolute', top: 40, right: 10}} color={'#4dd966'} icon="solid-check" />
                                        }


                                    </div>

                                </Link>);

                            }

                        )}
                        
                    </div>
                }

                {this.global.actividades && this.global.actividades.length == 0 &&
                    <Box style={{paddingVertical: 40}}>
                        <Text style={{textAlign: 'center', opacity: 0.5}}>No hay actividades por el momento...</Text>
                    </Box>
                }

                
            </Box>
        );

    }

}
