import React from 'reactn';
import Net from '../lib/Net';
import { fetchArchivosCompartidos } from '../services/Archivos';
import { Box, Icon, Button, Card, Heading } from 'bumbag';
import Andromeda from '../lib/Andromeda';
import { Link } from 'react-router-dom';

export default class Archivos extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
            grupoVisible: null
        }
  	}

  	async componentDidMount() {
        await fetchArchivosCompartidos();
        // seleccionar el 1er grupo
        var grupo_seleccionado = false;
        if(this.global.archivosCompartidos){
            this.global.archivosCompartidos.map(grupo => {
                if(grupo.archivos.length > 0 && !grupo_seleccionado){
                    this.setState({grupoVisible: grupo.id});
                    grupo_seleccionado = true;
                }
            });
        }
    }

    async eliminarArchivo(archivo_id){
    	if(window.confirm('¿Seguro que deseas eliminar este archivo?')){
            await Net.get('/archivos-grupos/' + archivo_id + '/eliminar');
            await fetchArchivosCompartidos();
        }
    }
    
  
  	render(){

        var hay_archivos = false;
        if(this.global.archivosCompartidos){
            this.global.archivosCompartidos.map(grupo => {
                if(grupo.archivos.length > 0) hay_archivos = true;
            });
        }

  		if(!this.global.archivosCompartidos){
  			return (<div>Cargando...</div>);
        }
        
  		return (
  			<Box>

                <Heading use={'h5'}>Archivos compartidos</Heading>

                {!hay_archivos &&
                    <Box marginTop="major-4">No hay archivos compartidos</Box>
                }
                
                {hay_archivos &&
                    <Box>
                        {this.global.archivosCompartidos.map((grupo, i) => {
                                if(grupo.archivos.length > 0){

                                    return (<Box maxWidth={'600px'} marginTop={'major-4'}>
                                                <a href="#" style={{fontSize: 16, color: '#666'}} key={"a"+i}  onClick={() => this.setState({grupoVisible: this.state.grupoVisible === grupo.id ? null : grupo.id})}>{grupo.nombre} <Icon size="100" icon={this.state.grupoVisible == grupo.id ? 'solid-chevron-down' : 'solid-chevron-right'} color={'#888'} /></a>
                                        
                                                {this.state.grupoVisible == grupo.id &&
                                                    <Box>
                                                        {grupo.archivos.map((archivo, j) => 
                                                            <Card ey={j} onClick={() => window.open(archivo.url, '_blank')}  cursor={'pointer'} className={'hoverBg'} marginTop={'major-4'} padding={'major-2'} position={'relative'}>
                                                                
                                                                {archivo.user_id == this.global.user.id &&
                                                                    <a style={{position: 'absolute', top: 10, right: 10}} onClick={(e) => {e.stopPropagation(); this.eliminarArchivo(archivo.id);}}><Icon icon="regular-trash-alt" color={'#666'} /></a>
                                                                }

                                                                <div style={{color: '#555', fontWeight: 'bold'}}>{archivo.titulo}</div>
                                                                {archivo.descripcion && archivo.descripcion != '' &&
                                                                    <div style={{color: '#888'}}>{archivo.descripcion}</div>
                                                                }

                                                                <div style={{fontSize: 12, marginTop: 10, color: '#888'}}><Icon icon="regular-user" size={15} color={'#888'} marginRight={1} /> {archivo.usuario}</div>
                                                            </Card>
                                                        )}
                                                    </Box>
                                                }
                                        </Box>
                                    );
                                }
                        })}
                    </Box>
                }

                {Andromeda.userEsDocente() &&
                    <Box marginTop={'major-4'}>
                        <Button palette={'primary'} onClick={() => this.props.history.push('/subir-archivo')}>Subir un archivo</Button>
                    </Box>
                }
                  
                  
	  			
	  		</Box>
		)
  	}
  	
}

