import { setGlobal, getGlobal } from 'reactn';
import Net from '../lib/Net';

export async function fetchMensajesRecibidos(){
    var busqueda_param = '';
    if(getGlobal().busquedaRecibidos.length > 3){
        var termino = getGlobal().busquedaRecibidos.replace(/\//g, '');
        busqueda_param = '?search=' + encodeURIComponent(termino);
    }
    var data = await Net.get('/mensajes/recibidos' + busqueda_param);
    setGlobal({ recibidos: data.result });
}

export async function fetchMensajesEnviados(){
    var data = await Net.get('/mensajes/enviados');
    setGlobal({ enviados: data.result });
}