import React, { useState } from 'react';
import { Box, Card, Button, Overlay } from 'bumbag';
import FetchItems from '../components/FetchItems';
import SimpleImageSlider from "react-simple-image-slider";

export default props => {

	const [fotos, setFotos] = useState([]);

	return (<Box>
				<Overlay.Container>
  					{overlay => (
  						<Box>
	  						
							<FetchItems url='/galerias' empty="No fotos por el momento...">
								{(item, i) => <Card key={i} marginBottom={4} width={600} title={item.nombre}>
									<Button use={Overlay.Show} {...overlay} onClick={() => setFotos(item.fotos)}>Ver galería</Button>
								</Card>}
							</FetchItems>

							

						    <Overlay fade slide {...overlay}>
						   		<SimpleImageSlider
				                    width={896}
				                    height={504}
				                    images={fotos}
				                />
						    </Overlay>
					    </Box>
					)}
				</Overlay.Container>
			</Box>
	);

}

