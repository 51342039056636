import React, { useState, useEffect, useGlobal } from 'reactn';
import { toaster } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import Net from '../lib/Net';
import SelectMultiple from '../components/SelectMultiple';
import { Box, Button, Heading, Textarea } from 'bumbag';
import { fetchCalificacionesConcepto } from '../services/Calificaciones';


export default function Calificar(props){

    const [editando, setEditando] = useState(false);
    const [alumnos, setAlumnos] = useState([]);
    const [calificacionId, setCalificacionId] = useState(0);
    const [alumnosSeleccionados, setAlumnosSeleccionados] = useState([]);
    const [valores, setValores] = useState([]);
    const [ready, setReady] = useState(false);
    const [promptGuardarPlantillaVisible, setPromptGuardarPlantillaVisible] = useState(false);
    const [modalSeleccionarPlantillaVisible, setModalSeleccionarPlantillaVisible] = useState(false);
    const [valoresSeleccionados, setValoresSeleccionados] = useState([]);
    const [observaciones, setObservaciones] = useState('');
    const [guardarPlantillaNombre, setGuardarPlantillaNombre] = useState('');
    const [loading, setLoading] = useState(false);
    const [conceptoActivo, setConceptoActivo] = useGlobal('conceptoActivo');
    const history = useHistory();

    useEffect(() => {
        init();
    }, []);


    const init = async () => {

        await fetchData();

        var concepto = conceptoActivo;
        var calificacion = props.location.state ? props.location.state.calificacion : null;
        var valores = concepto.escala.valores;
        setReady(true);
        setValores(valores.map(a => ({id: a.id.toString(), name: a.nombre, color: a.exitoso == 1 ? '#a7f3d0' : '#f8d2d0'})));
        
        if(calificacion){
            setEditando(true);
            setCalificacionId(calificacion.id);
            setObservaciones(calificacion.observaciones);
            setValoresSeleccionados([{id: calificacion.valor.id, name: calificacion.valor.nombre, color: calificacion.valor.exitoso == 1 ? '#a7f3d0' : '#f8d2d0'}]);
            setAlumnosSeleccionados([{id: calificacion.alumno.id , name: calificacion.alumno.nombre + ' ' + calificacion.alumno.apellido}]);
        }

    }

    const fetchData = async () => {

        var concepto = conceptoActivo;
        var calificacion = props.location.state ? props.location.state.calificacion : null;
        
        var data = concepto.alumnos.map(a => ({id: a.id.toString(), name: a.apellido + ' ' + a.nombre}));
       
        // filtra los alumnos que ya fueron calificados, solo si no se pasa una calificacion para editar como parametro
        var alumnos_a_calificar = [];
        for(var a of data){
            var alumno_calificado = false;
            for (var c of conceptoActivo.calificaciones) {
                if(c.alumno.id == a.id) alumno_calificado = true;
            }
            if(calificacion || !alumno_calificado) alumnos_a_calificar.push(a);
        }
        
        if(alumnos_a_calificar.length == 0){
            toaster.success('Ya calificaste a todos los alumnos, para modificar una calificación seleccionarla de la lista.');
            history.goBack();
            return false;
        }

        setAlumnos(alumnos_a_calificar);
        
    }


    const seleccionarAlumno = alumno => {
		var as = [...alumnosSeleccionados];
		as.push(alumno);
        setAlumnosSeleccionados(as);
    };
    

    const deseleccionarAlumno = index => {
		var as = [...alumnosSeleccionados];
		as.splice(index, 1);
		setAlumnosSeleccionados(as);
	};


    const seleccionarValor = valor => {
        setValoresSeleccionados([valor]);
    }


    const deseleccionarValor = () => {
        setValoresSeleccionados([]);
    }

    
    const calificar = async (version = null) => {

        if(loading) return false;

        if(version == 'nueva'){
            if(!window.confirm('Estas por archivar la calificación existente y aplicar la nueva. Está función el útil cuando el alumno está recuperando. ¿Deseas continuar?')){
                return false;
            }
        }
        
        if(alumnosSeleccionados.length == 0){
            toaster.danger('Por favor seleccionar alumnos para calificar');
            return false;
        }
        if(valoresSeleccionados.length == 0){
            toaster.danger('Por favor seleccionar una calificación');
            return false;
        }

        var concepto = conceptoActivo;
        
        var alumnos = alumnosSeleccionados.map(a => a.id);
        alumnos = alumnos.join();
        var valor = valoresSeleccionados[0];

        setLoading(true);

        let form_data = new FormData();

        form_data.append('alumnos', alumnos);
        form_data.append('calificacion_escala_valor_id', valor.id);
        form_data.append('observaciones', observaciones ? observaciones : '');
        form_data.append('concepto_id', concepto.id);
        if(version == 'nueva'){
            form_data.append('version', 'nueva');
        }
        
        var data = await Net.post('/calificar', form_data, true);
        
        setLoading(false);

        fetchCalificacionesConcepto(concepto.id);
        
        history.goBack();
        
    }

    

    const eliminar = async () => {
        if(!window.confirm('Estas por eliminar la calificación. En caso de existir, se volverá a una calificación anterior.  ¿Deseas continuar?')) return;
        var concepto = conceptoActivo;
        await Net.get('/calificaciones-eliminar/' + calificacionId);
        fetchCalificacionesConcepto(concepto.id);
        history.goBack();
    }


    const promptGuardarPlantilla = () => {
        setPromptGuardarPlantillaVisible(true);
    }

    const onPlantillaPress = (item) => {
        setObservaciones(item.contenido);
        setModalSeleccionarPlantillaVisible(false);
    }

    const guardarPlantilla = async (nombre) => {
        if(nombre == ''){
            alert('ingresar el nombre');
            return false;
        }
    	var params = {
            nombre: nombre,
            categoria: 'calificaciones',
    		contenido: observaciones
    	}
        setPromptGuardarPlantillaVisible(false);
        setGuardarPlantillaNombre('');
    	
        var result = await Net.post('/plantillas', params);
    }
    
    const mensajesGuardadosOpcionSeleccionada = (opcion) => {
        if(opcion == 1) {
            if(observaciones == ''){
                toaster.danger('Por favor escribí el mensaje primero');
                return false;
            }
            promptGuardarPlantilla();
        }
        if(opcion == 2) setModalSeleccionarPlantillaVisible(true);
    }

    var concepto = conceptoActivo;
    var grupo = concepto.grupo;

    if(!alumnos || !ready){
        return (<Box>cargando...</Box>);
    }

    console.log(alumnosSeleccionados);
    

    return (

        <Box maxWidth="600px">

            <Box>
                <Button marginBottom="major-4" iconBefore="solid-arrow-left" variant="ghost" onClick={() => history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
            </Box>

            <Box>
                <Heading use="h5" isSubHeading marginBottom="major-4">Calificando {concepto.nombre} en {grupo.nombre}</Heading>
            </Box>

            <Box marginBottom="major-4" style={{color: '#666'}}>Si el alumno ya ha sido calificado en esta actividad, se reemplazará dicha calificación.</Box>

            {alumnos.length > 0 &&
                <Box>
                    <SelectMultiple
                    style={{zIndex: 2, marginTop: 10}}
                    options={alumnos}
                    selected={alumnosSeleccionados}
                    txt_button={'Seleccionar alumno'}
                    txt_placeholder={'Buscar alumno...'}
                    txt_empty={'No se encontraron alumnos'}
                    handleAddition={seleccionarAlumno}
                    handleDelete={deseleccionarAlumno} />
                </Box>
            }
            
            <Box marginTop="major-2">
                <SelectMultiple
                style={{zIndex: 2, marginTop: 10}}
                options={valores}
                selected={valoresSeleccionados}
                txt_button={'Seleccionar calificación'}
                txt_empty={'No se encontraron posibles calificaciones'}
                handleAddition={seleccionarValor}
                handleDelete={deseleccionarValor} />
            </Box>

            <Box marginTop="major-4">
                <Textarea
                marginTop="major-2"
                rows={6}
                placeholder={'Observaciones'}
                onChange={e => setObservaciones(e.target.value)}
                value={observaciones ? observaciones : ''} />
            </Box>


            <Box marginTop="major-4">
                {editando &&
                    <Button marginRight="major-1" isLoading={loading} onClick={() => calificar('nueva')}>Guardar como nueva versión</Button>
                }

                <Button isLoading={loading} palette="primary" onClick={() => calificar()}>Guardar</Button>
            </Box>
            
            

        </Box>

    );

}