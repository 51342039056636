import Net from '../lib/Net';
import { getGlobal, setGlobal } from 'reactn';

export async function fetchCalificacionesConcepto(concepto_id){

    // setGlobal({ conceptoActivo: null });
    var data = await Net.get('/calificaciones/conceptos/' + concepto_id);
    setGlobal({ conceptoActivo: data.result });

}

export async function fetchConceptos(setearActivo = null){
    setGlobal({ readyConceptos: false });

    var filtro_grupos = getGlobal().conceptosFiltroGrupos;
    var filtro_ec = getGlobal().conceptosFiltroEC;

    var data = await Net.get('/calificaciones/conceptos?filtro_grupos=' + filtro_grupos + '&filtro_ec=' + filtro_ec);

    // actualizar las actividad activa, si hay alguna
    var nuevo_activo = null;
    var concepto_activo = setearActivo;
    if(getGlobal().conceptoActivo){
        concepto_activo = getGlobal().conceptoActivo.id;
    }
    if(concepto_activo){
        nuevo_activo = data.result.find(a => a.id == concepto_activo);
    }
    
    await setGlobal({ conceptos: data.result, conceptoActivo: nuevo_activo, readyConceptos: true });
}



