import { Alert, Box, Button, DropdownMenu, Flex, Heading } from 'bumbag';
import { Icon, toaster } from 'evergreen-ui';
import linkifyHtml from 'linkifyjs/html';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import React, { useGlobal } from 'reactn';
import Andromeda from '../lib/Andromeda';
import { formatHTML } from '../lib/Helpers';
import Net from '../lib/Net';
import { fetchActividadesDocentes } from '../services/Actividades';

export default props => {

    const history = useHistory();
    const [global_user] = useGlobal('user');
    const [actividad] = useGlobal('actividadActiva');


    const eliminarActividad = async () => {
        if(!actividad) {
            history.push('/actividades-docentes');
            return false;
        }
        if(!actividad.borrador && actividad.concepto && actividad.concepto.calificacion_cerrada != 0){
            toaster.danger('Esta actividad no se puede eliminar porque las calificaciones están cerradas');
            return false;
        }
        await Net.get('/actividades/' + actividad.id + '/eliminar');
        await fetchActividadesDocentes(true);
        history.push('/actividades-docentes');
        toaster.success('La actividad ha sido eliminada');
    }

    const duplicarActividad = async () => {
        await Net.get('/actividades/' + actividad.id + '/duplicar');
        await fetchActividadesDocentes(true);
        history.push('/actividades-docentes');
        toaster.success('La actividad ha sido duplicada y guardada como borrador');
    }

    if(!actividad) {
        return <div>cargando...</div>
    }

    var is_owner = actividad.docente_id == global_user.id;

    var archivos_docente = [];
    actividad.archivos.map((archivo, i) => {
        if(archivo.user_id == actividad.docente_id) archivos_docente.push(archivo);
    });


    console.log(actividad.espacio_curricular)

    return (

        <Box maxWidth="600px" width="100%">

            <Flex justifyContent="space-between">
                <Box>
                    <Button marginBottom="major-4" iconBefore="solid-arrow-left" variant="ghost" onClick={() => history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
                </Box>

                
                    
                    <Box>

                        <Flex>

                            {Andromeda.userEsDocente() && !actividad.borrador && is_owner &&
                                <Box>
                                    {/* {!!actividad.calificable &&
                                        <Button marginRight="major-2" onClick={() => history.push('/concepto', {concepto_id: actividad.concepto.id})} styleText={{color: '#666'}} styleButton={{backgroundColor: '#ddd', marginBottom: 20}}>Calificaciones</Button>
                                    } */}
                                    {is_owner &&
                                        <Button marginRight="major-2" onClick={() => history.push('/entregas')}>Entregas</Button>
                                    }
                                </Box>
                            }

                            {!Andromeda.userEsDocente() &&
                                <Box>

                                    {!!actividad.entregas && actividad.entregas.length > 0 &&
                                        <Button marginRight="major-1" secondary={true} onClick={() => history.push('/entregas')}>Mis entregas</Button>
                                    }

                                    <Button palette="primary" onClick={() => history.push('/enviar-entrega')}>Entregar actividad</Button>
                                    
                                </Box>
                            }

                            {Andromeda.userEsDocente() && is_owner &&

                                <Box>
                                    <DropdownMenu
                                    menu={
                                    <React.Fragment>
                                        <DropdownMenu.Item onClick={() => {
                                            if(!actividad.borrador && actividad.concepto && actividad.concepto.calificacion_cerrada == 1){
                                                toaster.danger('Esta actividad no se puede editar porque las calificaciones están cerradas');
                                            }else{
                                                history.push('/form-actividad', { grupo: actividad.grupo, editando: true })
                                            }
                                        }}>Editar</DropdownMenu.Item>
                                        <DropdownMenu.Item onClick={() => duplicarActividad()}>Duplicar</DropdownMenu.Item>
                                        <DropdownMenu.Item color="danger" onClick={() => { if(window.confirm('Estas por eliminar esta actividad. Se eliminarán tambien todas las calificaciones existentes. ¿Deseas continuar?')) { eliminarActividad() }}}>Eliminar</DropdownMenu.Item>
                                    </React.Fragment>
                                    }>
                                        <Button iconAfter="chevron-down">Opciones</Button>
                                    </DropdownMenu>
                                </Box>

                            }
                        </Flex>

                    </Box>
                

            </Flex>

            {!!actividad.borrador &&
                <Alert title="Borrador" marginBottom="major-4" type="warning">
                    Este es un borrador, la actividad todavía no está creada.
                </Alert>
            }

            <Heading use="h5" isSubHeading marginBottom="major-4">Actividad: {actividad.nombre}</Heading>

            <Box style={{border: '1px solid #ddd', borderRadius: 5}} marginBottom="major-4">    
                <Box padding="major-1">
                    <span style={styles.rowLabel}>Docente</span>
                    <span style={styles.rowContent}>{actividad.docente.apellido} {actividad.docente.nombre}</span>
                </Box>
                {!!actividad.grupo &&
                    <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                        <span style={styles.rowLabel}>Grupo</span>
                        <span style={styles.rowContent}>{actividad.grupo.nombre}</span>
                    </Box>
                }
                {!!actividad.espacio_curricular &&
                    <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                        <span style={styles.rowLabel}>Espacio curricular</span>
                        <span style={styles.rowContent}>{actividad.espacio_curricular.nombre}</span>
                    </Box>
                }
                <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                    <span style={styles.rowLabel}>Inicio</span>
                    <span style={styles.rowContent}>{moment(actividad.fecha_inicio).format('DD/MM/YYYY - HH:mm')} Hs</span>
                </Box>
                <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                    <span style={styles.rowLabel}>Entrega</span>
                    <span style={styles.rowContent}>{moment(actividad.fecha_entrega).format('DD/MM/YYYY - HH:mm')} Hs</span>
                </Box>
            </Box>

            <Box maxWidth={'500px'}>
                
                {actividad.descripcion && actividad.descripcion != '' &&                
                    <div dangerouslySetInnerHTML={{__html: linkifyHtml(formatHTML(actividad.descripcion))}}></div>
                }

                {archivos_docente.length > 0 &&
                    <div style={{marginTop: 40}}>
                        {archivos_docente.map((archivo, i) => {
                            return (<a href="#" key={i} className={'attachment'} onClick={() => window.open(archivo.url, '_blank')}><Icon icon="paperclip" size={20} /></a>)
                        })}
                    </div>
                }

            </Box>

            

        
        </Box>

    )
  	
}


const styles = {
	rowLabel: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 150
	},
	
	rowContent: {
	
	}
}

