import { Box, Group, Heading, Text } from 'bumbag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getGlobal } from 'reactn';
import MensajesSubmenu from '../components/MensajesSubmenu';
import Andromeda from '../lib/Andromeda';
import Net from '../lib/Net';



export default props => {

	const [data, setData] = useState([]);
	const [done, setDone] = useState(false);

	const fetchData = async () => {
		var d = await Net.get('/mensajes/' + getGlobal().mensaje_activo.mensaje_id + '/reporte');      
		setData(d);
		setDone(true);
	}
	
	useEffect(() => {		
        console.log('mensaje activo 2: ', getGlobal().mensaje_activo);

		fetchData();
	}, []);

	if(!done) return (<div>cargando...</div>);
	
	return (<Box>

            	<MensajesSubmenu page={'mensaje'} />

            	<Group orientation="vertical" marginBottom="40px" maxWidth="500px">

        			<Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>De</span>
        				<span style={styles.rowContent}>{getGlobal().mensaje_activo.emisor_nombre}</span>
        			</Box>

        			<Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Enviado a grupos</span>
        				<span style={styles.rowContent}>{data.grupos_string}</span>
        			</Box>
        			<Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Fecha de aprobación</span>
        				<span style={styles.rowContent}>{ data.mensaje.aprobado_at ? moment(data.mensaje.aprobado_at).format('DD/MM/YYYY - H:mm') : '-'}</span>
        			</Box>
        			
                    <Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Destinatarios</span>
        				<span style={styles.rowContent}>{data.mensaje_items.length}</span>
        			</Box>

        			<Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Leídos</span>
        				<span style={styles.rowContent}>{data.leidos}</span>
        			</Box>
        			
                    <Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Notificados</span>
        				<span style={styles.rowContent}>{data.notificados}</span>
        			</Box>
        			
                    <Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>Confirmados</span>
        				<span style={styles.rowContent}>{data.confirmados}</span>
        			</Box>

        			<Box borderBottom="default" display="flex" padding="6px">
        				<span style={styles.rowLabel}>No notificados</span>
        				<span style={styles.rowContent}>{data.no_notificados}</span>
        			</Box>

        		</Group>
        	
        		<Heading use="h5" marginTop={'major-5'} marginBottom="30px">Reporte por usuarios</Heading>

        		<Group orientation="vertical" marginBottom="6" maxWidth="500px">

        			{data.mensaje_items.map((mensaje_item, i) => {
						
						var dest = null;
						
						if(mensaje_item.docente){
							dest = mensaje_item.docente;
						}
						if(mensaje_item.alumno){
							dest = mensaje_item.alumno;
						}
						if(mensaje_item.tutor){
							dest = mensaje_item.tutor;
						}

						if(!dest) return null;
						
						return (
								<Box borderBottom="default" padding="8px" key={i}>
			        				<span style={styles.rowLabel2}>
			        					{dest.apellido} {dest.nombre}
			        					{!!mensaje_item.alumno &&
			        						<div>
			        							<div className="pastilla" style={{ borderColor: '#aaa', color: '#aaa' }}>{mensaje_item.alumno.apellido.toUpperCase()} {mensaje_item.alumno.nombre.toUpperCase()}</div>
			        						</div>
			        					}
			        				</span>
			        				<span style={styles.rowContent2}>
			        					{(mensaje_item.estado == 0) &&
											<div style={{fontSize:12, textTransform: 'uppercase', padding:5, borderRadius:5, display: 'inline', marginTop:5, backgroundColor: Andromeda.messageStatusColor(mensaje_item.estado)}}>
												<Text style={{color:'#666'}}>No notificado</Text>
											</div>
										}
										{(mensaje_item.estado == 1) &&
											<div style={{fontSize:12, textTransform: 'uppercase', padding:5, borderRadius:5, display: 'inline', marginTop:5, backgroundColor: Andromeda.messageStatusColor(mensaje_item.estado)}}>
												<Text style={{color:'#666'}}>Notificado</Text>
											</div>
										}
										{(mensaje_item.estado == 2) &&
											<div style={{fontSize:12, textTransform: 'uppercase', padding:5, borderRadius:5, display: 'inline', marginTop:5, backgroundColor: Andromeda.messageStatusColor(mensaje_item.estado)}}>
												<Text style={{color:'#666'}}>Leído</Text>
											</div>
										}
										{(mensaje_item.estado == 3) &&
											<div style={{fontSize:12, textTransform: 'uppercase', padding:5, borderRadius:5, display: 'inline', marginTop:5, backgroundColor: Andromeda.messageStatusColor(mensaje_item.estado)}}>
												<Text style={{color:'#666'}}>Confirmado</Text>
											</div>
										}
			        				</span>
            					</Box>);

            		})}

        		</Group>


	</Box>);


}

			




const styles = {
	rowLabel: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 200
	},
	rowContent: {
        width: 300
	},
	rowLabel2: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 350
	},
	rowContent2: {
        width: 150
	}
}


