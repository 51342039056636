import _ from 'lodash';
import moment from 'moment';
import { Box, Button, Heading, Textarea, Input } from 'bumbag';
import Andromeda from '../lib/Andromeda';
import { Icon, toaster } from 'evergreen-ui';
import { useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'reactn';
import ChatBubble from '../components/ChatBubble';
import Net from '../lib/Net';

export default props => {

    const history = useHistory();

    if(!props.location.state){
        history.goBack();
        return false;
    }

    // route params
    const { entrega_id } = props.location.state;

    // manejo de componente
    var windowHeight = window.innerHeight;
    var windowWidth = window.innerWidth;
    var scrollViewRef = useRef();
    var isMounted = useRef(false);
    
    var interval;

    // estado
    var [entrega, setEntrega] = useState(null);
    var [comentario, setComentario] = useState('');
    

    var fetchEntrega = async function(){
        var data = await Net.get('/actividades/entregas/' + entrega_id);
        console.log(data);
        setEntrega(data.result);
    }

    
    var scrollChatToEnd = () => {
        if(!scrollViewRef || !scrollViewRef.current) return;
        setTimeout(function(){
            if(!scrollViewRef || !scrollViewRef.current) return;
            scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
        }, 500);
    }


    useEffect(() => {
        init();
        // true o false si esta pantalla esta montada
        isMounted.current = true;
        return () => { isMounted.current = false }
    },[]);
    

    const init = async () => {
        // cada 8 seg recuperamos mensajes de chat
        interval = setInterval(async () => {
            if(!isMounted.current) return;
            await fetchEntrega();
            scrollChatToEnd();
        }, 8000);
        await fetchEntrega();
        scrollChatToEnd();
        return () => {
            // Anything in here is fired on component unmount.
            clearInterval(interval);
        }
        
    }

    

   
    var enviarComentario = async () => {
        let form_data = new FormData();
        if(comentario == '') return;
        form_data.append('comentario', comentario);
        setComentario('');
        await Net.post('/actividades/entregas/' + entrega.id + '/enviar-comentario', form_data, true)
        fetchEntrega();
        scrollChatToEnd();
    }

    if(!entrega) return <Box style={{padding: 40}}>cargando...</Box>;

    console.log(entrega.actividad);
    
    return (
        <Box width="100%" maxWidth="600px">
            <Box marginBottom="major-4">
                <Button iconBefore="solid-arrow-left" variant="ghost" onClick={() => history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
            </Box>
            <Box style={{height: windowHeight - 260}}>

                <Box>

                    <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entrega en {entrega.actividad.nombre}</Heading>


                    <Box style={{border: '1px solid #ddd', borderRadius: 5}} marginBottom="major-4">    
                        
                        <Box padding="major-1">
                            <span style={styles.rowLabel}>De</span>
                            <span style={styles.rowContent}>{entrega.alumno.apellido + ' ' + entrega.alumno.nombre}</span>
                        </Box>

                        <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                            <span style={styles.rowLabel}>Actividad</span>
                            <span style={styles.rowContent}>{entrega.actividad.nombre}</span>
                        </Box>

                        {!!entrega.actividad.espacio_curricular &&
                            <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                                <span style={styles.rowLabel}>Espacio curricular</span>
                                <span style={styles.rowContent}>{entrega.actividad.espacio_curricular.nombre}</span>
                            </Box>
                        }

                        <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                            <span style={styles.rowLabel}>Grupo</span>
                            <span style={styles.rowContent}>{entrega.actividad.grupo.nombre}</span>
                        </Box>
                    
                        <Box style={{borderTop: '1px solid #ddd'}} padding="major-1">
                            <span style={styles.rowLabel}>Fecha</span>
                            <span style={styles.rowContent}>{moment(entrega.created_at).format('DD/MM/YYYY - HH:mm') + ' Hs' }</span>
                        </Box>
                    
                    </Box>


                    <Box style={{marginTop: 20, marginBottom: 20}}>
                        {entrega.contenido}
                    </Box>
                    
                    {!!entrega.archivos && entrega.archivos.length > 0 &&
                        <Box style={{marginTop: 10, paddingBottom: 20}}>
                            {entrega.archivos.map((archivo, i) =>
                                <a href={archivo.url} target="_blank" key={i} className={'attachment'}><Icon icon="paperclip" size={20} /></a>
                            )}
                        </Box>
                    }

                    
                    <Box style={{fontWeight: 'bold'}} marginBottom="major-1">Comentarios</Box>

                    {entrega.comentarios && entrega.comentarios.length > 0 &&
                                   
                        <Box ref={scrollViewRef} marginBottom="major-2" style={{overflowY: 'scroll', background: '#fafafa', borderRadius: 10, border: '1px solid #ccc', height: 200}}>
                            <Box style={{paddingTop: 20, paddingBottom: 50, paddingVertical: 10}}>
                                {entrega.comentarios.map(comentario => {
                                    
                                    if(Andromeda.userEsDocente()){
                                        if(comentario.user_id == entrega.actividad.docente_id){
                                            return (<ChatBubble direccion="enviado" text={comentario.comentario} />);
                                        }else{
                                            return (<ChatBubble text={comentario.comentario} />);
                                        }
                                    }else{
                                        if(comentario.user_id == entrega.actividad.docente_id){
                                            return (<ChatBubble text={comentario.comentario} />);
                                        }else{
                                            return (<ChatBubble direccion="enviado" text={comentario.comentario} />);
                                        }
                                    }
                                    
                                })}
                            </Box>
                        </Box>
                    
                    }
                    <Box style={{ width: '100%', height: 50, display: 'flex' }}>

                        <Box style={{ flexGrow: 1 }}>
                            <Input placeholder="escribir un comentario" value={comentario} onKeyDown={e => { if (e.key === 'Enter') { enviarComentario() }}} onChange={e => setComentario(e.target.value)} />
                        </Box>

                        <Box style={{ width: 100, paddingLeft: 10}}>
                            <Button style={{width: '100%'}} onClick={() => enviarComentario()}>Enviar</Button>
                        </Box>

                    </Box>

                </Box>
                
            </Box>

            

        </Box>
    );

}



const styles = {
	rowLabel: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 150
	},
	
	rowContent: {
	
	}
}


