import React from 'reactn';
import Net from '../lib/Net';
import { fetchComprobantes } from '../services/Comprobantes';
import { Text, Box  } from 'bumbag';
import { Link } from 'react-router-dom';

export default class Comprobantes extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
            grupoVisible: null
        }
  	}

  	async componentDidMount() {
        await fetchComprobantes();
    }

    render(){

        var hay_comprobantes = false;
        if(this.global.comprobantes){
                hay_comprobantes = true;
        }

  		if(!this.global.comprobantes){
  			return (<div>Cargando...</div>);
        }
        
  		return (
  			<Box>

                {!hay_comprobantes &&
                    <Box marginTop="major-4">No hay comprobantes</Box>
                }
               
                {hay_comprobantes &&
                    <Box maxWidth={'600px'} marginTop={'major-4'}>
                        {this.global.comprobantes.facturas.map((comprobante, i) => {

                            return (
                                <Link key={i} onClick={() => window.open(comprobante.factura_url, '_blank')}  style={{textDecoration: 'none'}}>
                                    <div className="listaItem">
                                        <div style={{marginRight: 90}}>
                                            <Text color="text300" className={'asunto'}> ${comprobante.monto}</Text><br />
                                        </div>
                                        <div className="listaItemFecha"><Text fontSize="150" color="text300">{comprobante.fecha}</Text></div>
                                        
                                    </div>
                                </Link>
                            );


                        })}
                    </Box>
                }
	  		</Box>
		)
  	}
  	
}

