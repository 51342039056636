import { getGlobal, setGlobal } from 'reactn';
import { toaster } from 'evergreen-ui';
import { fetchMensajesRecibidos, fetchMensajesEnviados } from '../services/Mensajes';
import moment from 'moment';

export function debug(msg){
    console.log(msg);
	if(getGlobal().user.email === 'd@a'){
        toaster.danger('debug', msg);
    }
}

export function validarEmail(email) {
    if(email === 'd@a') return true;
	var re = /[^@]+@[^@]+\.[^@]+/;
    return re.test(email);
}

export async function setStorage(label, item){
    var val = '';
    if(item !== null) val = item.toString();
    await localStorage.setItem(label, val);
}

export async function getStorage(label){
    return await localStorage.getItem(label);
}

export async function seleccionarRol(rol_id){
	console.log('por seleccionar:' + rol_id);
	var roles = getGlobal().user.roles;
	var selected_rol = roles[0];
	console.log(roles);
	roles.map((rol) => {
		if(rol.users_roles_id == rol_id) selected_rol = rol;
	});
	console.log('seleccionando rol:' + selected_rol.users_roles_id);
	await setGlobal({rol: selected_rol});
	fetchMensajesRecibidos();
	fetchMensajesEnviados();
}

export function formatHTML(text){
	return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export function cleanHTML(str){
    if(!str) return null;
    //str = str.replace(/\\"/g, 'x');
    console.log(str);
    return str;
    //return str.replace(/font-family\:[^;]+;?|font-size\:[^;]+;?|line-height\:[^;]+;?/g, '');
}

export function fechaAmigable(fecha){

    var TODAY = moment().clone().startOf('day');
    var YESTERDAY = moment().clone().subtract(1, 'days').startOf('day');
    var A_WEEK_OLD = moment().clone().subtract(7, 'days').startOf('day');
    var momentDate = moment(fecha, "DD/MM/YYYY H:m:s");

    // es de hoy o de ayer
    if(momentDate.isSame(TODAY, 'd') || momentDate.isSame(YESTERDAY, 'd')){
        return momentDate.fromNow();
    }

    return momentDate.format('DD/MM/YYYY');
    
}


