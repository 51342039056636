import React, { useState, useEffect } from 'reactn';
import { Flex, InputField, Box } from 'bumbag';

export default function DateTimePicker(props){

    const [fecha, setFecha] = useState('');
    const [hora, setHora] = useState('');

    useEffect(() => {
        if(props.value){
            var parts = props.value.split(' ');
            setFecha(parts[0]);
            setHora(parts[1]);
        }
    }, []);

    const seleccionarFecha = fecha => {
        setFecha(fecha);
        props.onChange(fecha + ' ' + hora);
    }

    const seleccionarHora = hora => {
        setHora(hora);
        props.onChange(fecha + ' ' + hora);
    }
  
	return (
	    <Flex>
            <Box>
	    	    <InputField label={props.labelFecha} marginRight="major-2" type="date" value={ fecha } onChange={e => seleccionarFecha(e.target.value)} />
            </Box>
            <Box>
	    	    <InputField label={props.labelHora} type="time" value={ hora } onChange={e => seleccionarHora(e.target.value)} />
            </Box>
	    </Flex>
	);

}

