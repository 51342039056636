import React, { useState, useEffect } from 'react';
import { Spinner } from 'bumbag'
import Net from '../lib/Net';

export default props => {

	const [data, setData] = useState([]);
	const [listo, setListo] = useState(false);

	

	useEffect(() => {		

		const fetchData = async () => {
			var data = await Net.get(props.url);
			setData(data.result);
			setListo(true);
		}
		
		fetchData();
	}, [props.url]);

	if(!listo) return (<div><Spinner /></div>);

	if(data.length === 0) return (<div>{props.empty}</div>);
	
	if(listo){
		return  data.map((item, i) => {
			return props.children(item, i);
		});
	}
			
}	