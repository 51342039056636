import { Box, Button, Heading } from 'bumbag';
import moment from 'moment';
import { Link } from "react-router-dom";
import React from 'reactn';
import Andromeda from '../lib/Andromeda';
import Net from '../lib/Net';
import { fetchActividadesAlumnos, fetchActividadesDocentes } from '../services/Actividades';


export default class Entregas extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            entregas: null,
            fotos: [],
            archivos: [],
            loading: false,
        }
    }

    async fetchEntregas(){
        var actividad = this.global.actividadActiva;
        var data = await Net.get('/actividades/' + actividad.id + '/entregas');
        this.setState({entregas: data.result});
    }

    componentDidMount(){    
        this.fetchEntregas();
    }
    
    async eliminarArchivo(archivo_id){

        var actividad = this.global.actividadActiva;
        
        if(!window.confirm('Seguro que deseas eliminar este archivo?')) return false;
        await Net.get('/actividades-archivos/' + archivo_id + '/eliminar');
        if(Andromeda.userEsDocente())
            await fetchActividadesDocentes(true);
        else
            await fetchActividadesAlumnos(true);

    }

    
    async submit(){

        if(this.state.loading) return false;

        this.setState({loading: true});
        
        // al subir el archivo, pasamos user_id con el id del dueño del archivo
        // si es docente será el mismo, si es tutor, será el id del alumno
        var user_id = this.global.user.id;
        if(!Andromeda.userEsDocente()){
            user_id = this.global.actividadesAlumnoActivo.id;
        }

        var actividad = this.global.actividadActiva;
    	
        if(this.state.fotos.length == 0 && this.state.archivos.length == 0){
            // no hay archivos para subir
            this.props.history.goBack();
            return false;
        }

        
    	
        this.setState({archivos: [], fotos: [], loading: false});
        this.fm.limpiar();
        
        
        await fetchActividadesAlumnos();
        

        this.setState({loading: false});

    }
    
    render() {

        if(!this.state.entregas){
            return <Box>cargando...</Box>
        }

        var actividad = this.global.actividadActiva;
        
        var entregas = [];
        
        // si el user es docente muestra todas las entregas
        // sino solo las del alumno
        if(!!this.state.entregas){
            this.state.entregas.map(entrega => {            
                if(Andromeda.userEsDocente()){
                    entregas.push(entrega);
                }else{
                    if(entrega.alumno_id == this.global.actividadesAlumnoActivo.id) entregas.push(entrega);
                }
            });
        }

        return (
            <Box maxWidth="600px">

                <Button iconBefore="solid-arrow-left" variant="ghost" onClick={() => this.props.history.goBack()} className={'btn-mensaje-volver'}> Volver</Button>
                
                <Box>

                    {Andromeda.userEsDocente() &&
                        <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entregas en {actividad.nombre}</Heading>
                    }

                    {Andromeda.userEsTutor() &&
                        <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entregas de {this.global.actividadesAlumnoActivo.nombre} {this.global.actividadesAlumnoActivo.apellido} en {actividad.nombre}</Heading>
                    }

                    {Andromeda.userEsAlumnoIndependiente() &&
                        <Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">Entregas en {actividad.nombre}</Heading>
                    }                    
                    
                    <Box style={{marginTop: 0, marginBottom: 50}}>

                        {!Andromeda.userEsDocente() && entregas.length == 0 &&
                            <div style={{lineHeight: 20, marginTop: 30, marginBottom: 30, opacity: 0.5, textAlign: 'center'}}>Todavía no enviaste ninguna entrega</div>
                        }

                        {!!Andromeda.userEsDocente() && entregas.length == 0 &&
                            <div style={{lineHeight: 20, marginTop: 30, opacity: 0.5, textAlign: 'center'}}>Todavía no hay entregas en esta actividad</div>
                        }
                        
                        {!!entregas &&
                            <Box>
                                {entregas.map((entrega, i) =>

                                    <Link to={{pathname: '/entrega', state: { entrega_id: entrega.id }}} key={i}>

                                        <div className="listaItem">
                                            <div style={{ flexDirection: 'row' }}>
                                                <div>
                                                    {entrega.alumno &&
                                                        <div style={{fontWeight: 'bold'}}>{entrega.alumno.apellido + ' ' + entrega.alumno.nombre}</div>
                                                    }
                                                    <div style={{marginTop: 5, opacity: 0.5}}>{moment(entrega.created_at).format('DD/MM/YYYY - HH:mm') + ' Hs' }</div>
                                                </div>
                                                <div style={{flexGrow: 1, alignItems: 'flex-end'}}></div>
                                            </div>
                                        </div>
                                    
                                    </Link>

                                )}
                            </Box>
                        }
                    

                        

                    </Box>
                    

                </Box>
                

            </Box>
        );

    }

}

