import React from 'react';
import { Box, Card } from 'bumbag';
import FetchItems from '../components/FetchItems';
import _ from 'lodash';

export default props => {

	return (<Box>
				<FetchItems url='/noticias' empty="No hay noticias">
					{(item, i) => <Card key={i} marginBottom={4} width={600} title={item.titulo}>
						<div dangerouslySetInnerHTML={{__html: _.truncate(item.contenido, {'length': 150})}}></div>
					</Card>}
				</FetchItems>
			</Box>
	);

}	