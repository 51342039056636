import React from 'reactn';
import { DropdownMenu, Button } from 'bumbag';
import MainMenuOptions from './MainMenuOptions';
import { Menu as IconMenu } from 'react-feather';

export default function MainMenu(){
  
	return (
	    <div style={{marginRight: 20}} className="dropdownMainMenu">
	    	<DropdownMenu menu={<MainMenuOptions />}>
				<Button kind="ghost"><IconMenu /></Button>
			</DropdownMenu>
	    </div>
	);

}

