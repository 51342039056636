import React from 'react';
import { Button } from 'bumbag';

export default class extends React.Component{

	constructor(props) {
    	super(props);
    	this.seleccionarArchivo = this.seleccionarArchivo.bind(this);
  	}

  	seleccionarArchivo(e){
  		var archivos = [];
  		var files = e.target.files;
  		for (var i = 0; i < files.length; i++) {
  			archivos.push(files.item(i));
  		}
  		this.props.onChange(archivos);
  	}

  	async componentDidMount(){
    	this.fileSelector = document.createElement('input');
        this.fileSelector.setAttribute('type', 'file');
        if(this.props.multiple !== false){
            this.fileSelector.setAttribute('multiple', 'multiple');
        }
  		this.fileSelector.onchange = this.seleccionarArchivo;
    }
  
  	render(){
          var size = 'small';
          if(this.props.size) size = this.props.size;
          var label = 'Adjuntar archivos';
          if(this.props.label) label = this.props.label;
  		  return (
	    	    <Button size={size} onClick={() => this.fileSelector.click()} marginBottom="2">{label}</Button>
		  )
  	}
  	
}

