import React from 'reactn';
import { Link } from 'react-router-dom';
import { Heading, Group, Box, Tag, Button, Text, Textarea, Icon as FannyIcon } from 'bumbag';
import Net from '../lib/Net';
import MensajesSubmenu from '../components/MensajesSubmenu';
import FilePicker from '../components/FilePicker';
import { fetchMensajesRecibidos, fetchMensajesEnviados } from '../services/Mensajes';
import _ from 'lodash';
import { Icon, toaster } from 'evergreen-ui';
import Andromeda from '../lib/Andromeda';
import { formatHTML } from '../lib/Helpers';
import Config from '../Config';
import linkifyHtml from 'linkifyjs/html'


export default class Mensaje extends React.Component{

	constructor(props) {
    	super(props);
    	this.state = {
    		respuesta: '',
    		confirmado: false,
    		busyConfirmar: false,
    		busyRespuesta: false,
    		archivos: [],
    	}
  	}

  	async componentDidMount(){
  		var item = this.global.mensaje_activo;

        console.log('mensaje activo: ', this.global.mensaje_activo);
  		
  		// TODO: agregar el caso de que el mensaje haya sido enviado por otros docentes
  		// que se marque como leído solo si el mensaje viene de 'recibidos'
		if (item.mensaje_admin != 1 && item.estado < 2 && item.emisor_id != this.global.user.id) {
            await Net.post('/mensajes/' + item.id + '/estado/2');
            var mensaje_activo = this.global.mensaje_activo;
            mensaje_activo.estado = 2;
            this.setGlobal({mensaje_activo});
            fetchMensajesRecibidos();
		}
        
		this.setState({ confirmado: item.estado === 3 });

  	}

  	async confirmarLectura(){
  		this.setState({ busyConfirmar: true });
        await Net.post('/mensajes/' + this.global.mensaje_activo.id + '/estado/3');    
        toaster.success('La lectura del mensaje ha sido confirmada');
        this.setState({ busyConfirmar: false, confirmado: true });
        var mensaje_activo = this.global.mensaje_activo;
        mensaje_activo.estado = 3;
        this.setGlobal({mensaje_activo});
  	}	

  	adjuntarArchivos(files){
    	var archivos = this.state.archivos.slice();
    	files.map(file => {
    		var agregar = true;
	    	archivos.map(archivo => {
	    		if(archivo.name === file.name) agregar = false;
	    	});
	    	if(agregar) archivos.push(file);
    	});
	    this.setState({archivos});
    }

    quitarArchivo(quitar){
    	console.log('quitar');
    	var archivos = this.state.archivos.slice();
    	var indice = -1;
    	archivos.map((a, i) => {
    		if(a.name === quitar.name) indice = i;
    	});
    	if(indice > -1){
    		archivos.splice(indice, 1);
    		this.setState({archivos});
    	}
    }

    async enviarArchivo(archivo){
        let fd = new FormData();
        fd.append('archivo', archivo);
        var data = await Net.post('/archivos', fd, true);
        return data.result.id;
    }


    async enviarRespuesta(){
  		
  		if (this.state.respuesta === '') {
			toaster.danger('Por favor escribir un mensaje');
			return false;
		}
		this.setState({ busyRespuesta: true });

		var form_data = new FormData();
        var archivos = [];

        var promises_archivos = this.state.archivos.map(async (archivo) => {
        	var archivo_id = await this.enviarArchivo(archivo);
        	archivos.push(archivo_id);
        });

        await Promise.all(promises_archivos);

        if(this.state.archivos.length > 0){
        	form_data.append('archivos', archivos.join());
        }

        form_data.append('mensaje', this.state.respuesta);
        form_data.append('mensaje_id', this.global.mensaje_activo.mensaje_id);
        form_data.append('alumno_id', this.global.mensaje_activo.alumno_id);
        form_data.append('es_docente', Andromeda.userEsDocente() ? 1 : 0);
		
        await Net.post('/mensajes/respuesta', form_data, true);
        toaster.success('Respuesta enviada', {description: 'El mensaje ha sido enviado correctamente'});
        this.setState({ busyRespuesta: false });
        
        fetchMensajesRecibidos();
        fetchMensajesEnviados();
        
        document.querySelector('.btn-mensaje-volver').click();
  	}


  	render(){
  		
  		return (
  			<Box>

                <MensajesSubmenu page={'mensaje'} />

                
            	<Box style={{border: '1px solid #ddd', borderRadius: 5}} maxWidth="500px" marginBottom="major-2">
                    
                    <Box padding="major-1"><span style={styles.rowLabel}>De</span><span style={styles.rowContent}>{this.global.mensaje_activo.emisor_nombre}</span></Box>
            		
                    {this.global.mensaje_activo.mensaje_admin != 1 &&
                        <Box style={{borderTop: '1px solid #ddd'}} padding="major-1"><span style={styles.rowLabel}>Para</span><span style={styles.rowContent}>{this.global.mensaje_activo.destinatarios ? _.trimStart(this.global.mensaje_activo.destinatarios.split(',').join(', ')) : ''}</span></Box>
                    }

            		{this.global.mensaje_activo.alumno_nombre && !Andromeda.userEsAlumnoIndependiente() &&
            			<Box style={{borderTop: '1px solid #ddd'}} padding="major-1"><span style={styles.rowLabel}>Alumno</span><span style={styles.rowContent}>{this.global.mensaje_activo.alumno_nombre}</span></Box>
            		}

            		{this.global.mensaje_activo.grupos_alumno &&
            			<Box style={{borderTop: '1px solid #ddd'}} padding="major-1"><span style={styles.rowLabel}>Grupo</span><span style={styles.rowContent}>{_.trimStart(this.global.mensaje_activo.grupos_alumno)}</span></Box>
            		}

            		<Box style={{borderTop: '1px solid #ddd'}} padding="major-1"><span style={styles.rowLabel}>Fecha</span><span style={styles.rowContent}>{this.global.mensaje_activo.fecha}</span></Box>
            		
                    {this.global.mensaje_activo.emisor_id == this.global.user.id &&
            			<Box style={{borderTop: '1px solid #ddd'}} padding="major-1"><Link to={'/reporte'}><Button>Reporte de envío</Button></Link></Box>
            		}
                </Box>
            	

            	{this.global.mensaje_activo.archivos && this.global.mensaje_activo.archivos.length > 0 &&
            		<div style={{marginBottom: 20}}>
            			{this.global.mensaje_activo.archivos.map((archivo, i) => {
            				return (<a href="#" key={i} className={'attachment'} onClick={() => window.open(archivo.url, '_blank')}><Icon icon="paperclip" size={20} /></a>)
            			})}
            		</div>
            	}

            	<Box maxWidth={'600px'}>
  					<Heading use="h5" isSubHeading marginBottom="major-4" marginTop="major-4">{this.global.mensaje_activo.titulo}</Heading>
  					<div dangerouslySetInnerHTML={{__html: linkifyHtml(formatHTML(this.global.mensaje_activo.mensaje))}}></div>
  				</Box>

  				{this.global.mensaje_activo.requiere_confirmacion == '1' && !this.state.confirmado &&
  					<Box maxWidth="500px" padding="5" marginTop="10" marginBottom="10">
  						<Text fontWeight="bold">Este mensaje requiere confirmación de lectura</Text><br /><br />
  						<Button palette={'primary'} size={'medium'} isLoading={this.state.busyConfirmar} onClick={() => this.confirmarLectura()}>Confirmo que he leído este mensaje</Button>
  					</Box>
  				}

  				{this.global.mensaje_activo.requiere_confirmacion == '1' && this.state.confirmado &&
  					<Box  maxWidth="500px" padding="5" marginTop="10" marginBottom="10">
  						<FannyIcon marginRight={2} icon={'solid-check'} />
  						<Text fontWeight="bold">Ya has confirmado la lectura de este mensaje</Text>
  					</Box>
  				}
  				
  				{this.global.user.id !== this.global.mensaje_activo.emisor_id &&
  					(Andromeda.userEsDocente() || (Andromeda.userEsTutor() && Config.padresPuedenResponderMensajes)) && this.global.mensaje_activo.mensaje_admin != 1 &&
					
                    <Box marginTop={'major-5'} marginBottom={'major-5'} maxWidth="500px">
                        <Textarea rows={6} placeholder="Responder mensaje..." value={this.state.respuesta} onChange={e => this.setState({respuesta: e.target.value})} />
                        <FilePicker onChange={files => this.adjuntarArchivos(files)} />
                        {this.state.archivos.length > 0 && 
                            <div>
                            	{this.state.archivos.map((a, i) => {
                            		return (<Tag palette="secondary" style={{marginRight: 5}} key={i} onRemove={() => this.quitarArchivo(a)}>{a.name}</Tag>);
                            	})}
                            </div>
                        }
                        <Box textAlign={'right'} marginTop="major-2">
							<Button loading={this.state.busyRespuesta} isLoading={this.state.busyRespuesta} onClick={() => this.enviarRespuesta()}>Enviar respuesta</Button>
						</Box>
					</Box>
				}

  			</Box>
		)
  	}
  	
}

const styles = {
	rowLabel: {
		fontWeight: 'bold',
		display: 'inline-block',
		width: 80
	},
	
	rowContent: {
	
	}
}
