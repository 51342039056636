import React from 'reactn';
import cogoToast from 'cogo-toast';
import MensajesSubmenu from '../components/MensajesSubmenu';
import { fetchMensajesRecibidos, fetchMensajesEnviados } from '../services/Mensajes';
import SelectMultiple from '../components/SelectMultiple';
import FilePicker from '../components/FilePicker';
import MensajesGuardados from '../components/MensajesGuardados';
import { Input, Textarea, Switch, Button, Tag, Spinner } from 'bumbag';
import Andromeda from '../lib/Andromeda';
import Config from '../Config';
import Net from '../lib/Net';

export default class NuevoMensaje extends React.Component{

	constructor(props) {
        super(props);
        
        this.state = {
            tags: null,
            alumnos: null,
            grupos: null,
            hijos: null,
            docentes: null,
            divisiones: null,
            showPrealoader: true,
            gruposSeleccionados: [],
            alumnosSeleccionados: [],
            docentesSeleccionados: [],
            divisionSeleccionada: [],
            hijosSeleccionados: [],
            requiere_confirmacion: false,
            enviar_a_docentes: false,
            archivos: [],
            busy: false,
        }
    }

    async componentDidMount(){
    	await this.fetchDivisiones();
    }

    addGrupo = grupo => {
		var gruposSeleccionados = this.state.gruposSeleccionados;
		gruposSeleccionados.push(grupo);
		this.setState({ gruposSeleccionados, alumnosSeleccionados: [], docentesSeleccionados: [] });
	};

	seleccionarTodosGrupos = () => {
        this.setState({ gruposSeleccionados: this.state.grupos, alumnosSeleccionados: [], docentesSeleccionados: [] });
    }

	deSeleccionarTodosGrupos = () => {
        this.setState({ gruposSeleccionados:[] });
    }

    addAlumno = alumno => {
		var alumnosSeleccionados = this.state.alumnosSeleccionados;
		alumnosSeleccionados.push(alumno);
		this.setState({ alumnosSeleccionados, enviar_a_docentes: false, gruposSeleccionados: [], docentesSeleccionados: [] });
	};

	addDocente = docente => {
		var docentesSeleccionados = this.state.docentesSeleccionados;
		docentesSeleccionados.push(docente);
		this.setState({ docentesSeleccionados, enviar_a_docentes: false, alumnosSeleccionados: [], gruposSeleccionados: [] });
	};

    addHijo = hijo => {
    	console.log('add hijo');
        this.setState({ hijosSeleccionados: [hijo], docentes: null }, () => {
            this.fetchDocentes(this.state.divisionSeleccionada[0].id);
        });
	};
    
	deleteAlumno = index => {
		var alumnosSeleccionados = [...this.state.alumnosSeleccionados];
		alumnosSeleccionados.splice(index, 1);
		this.setState({ alumnosSeleccionados });
	};

	deleteDocente = index => {
		var docentesSeleccionados = [...this.state.docentesSeleccionados];
		docentesSeleccionados.splice(index, 1);
        this.setState({ docentesSeleccionados });
    };
    
    seleccionarTodosDocentes = () => {
        this.setState({ docentesSeleccionados: this.state.docentes, alumnosSeleccionados: [], gruposSeleccionados: [] });
    }

    deSeleccionarTodosDocentes = () => {
        this.setState({ docentesSeleccionados: [] });
    }

	deleteGrupo = index => {
		var gruposSeleccionados = [...this.state.gruposSeleccionados];
		gruposSeleccionados.splice(index, 1);
		this.setState({ gruposSeleccionados });
		if(gruposSeleccionados.length === 0){
			console.log('no hay grupos');
			this.setState({enviar_a_docentes: false});
		}
    };
    
    deleteHijo = index => {
        // solo un hijo puede estar seleccionado por vez
		this.setState({ hijosSeleccionados: [], docentesSeleccionados: [], docentes: null });
	};

    onSelectDivision = async (data) => {
    	console.log('on select division');
    	console.log(data);
        this.setState({
            divisionSeleccionada: [data],
            gruposSeleccionados: [],
            docentesSeleccionados: [],
            alumnosSeleccionados: [],
            alumnos: null,
            grupos: null,
            hijos: null,
            docentes: null,
        });
    	// si se esta de-seleccionando una division, salir sin hacer fetch
    	// de grupos, alumnos y docentes
    	if(data.length === 0) return;

    	if(Andromeda.userEsDocente()){
        	await this.fetchGrupos(data.id);
        	await this.fetchAlumnos(data.id);
        	await this.fetchDocentes(data.id);
        }
        if(Andromeda.userEsAlumnoIndependiente()){
        	await this.fetchDocentes(data.id);
        }
        if(Andromeda.userEsTutor()){
            await this.fetchHijos(data.id);
            if(this.state.hijosSeleccionados.length > 0){
                await this.fetchDocentes(data.id);
            }
        }
    }


    deleteDivision = index => {
        // solo un hijo puede estar seleccionado por vez
		this.setState({ divisionSeleccionada: [] });
	};

    async fetchDivisiones(){
        var data = await Net.get('/divisiones');
        if(data.result.length === 0){
        	cogoToast.error('No es posible acceder a los niveles, por favor comunicarse con la institución');
        	this.props.history.goBack();
        }
        if(data.result.length === 1){
        	this.setState({ divisiones: data.result });
        	await this.onSelectDivision(data.result[0]);
        }
        if(data.result.length > 1){
        	this.setState({ divisiones: data.result.map(d => ({id: d.id.toString(), name: d.nombre})) });	
        	// si el usuario es tutor, no es necesaria la division,
        	// la division la determina el hijo, para no mostrar el combo de seleccion de division
        	// seleccionamos la primera
        	if(Andromeda.userEsTutor()){
        		await this.onSelectDivision(data.result[0]);		
        	}
        }
        this.setState({showPrealoader: false});
    }

    async fetchGrupos(division_id){
        this.setState({ gruposSeleccionados: [] });
        var data = await Net.get('/grupos?division_id=' + division_id);
        this.setState({
            grupos: data.result.map(g => ({id: g.id.toString(), name: g.nombre}))
        });
    }    

    async fetchAlumnos(division_id){
        this.setState({ alumnosSeleccionados: [] });
        var data = await Net.get('/alumnos?division_id=' + division_id);
        this.setState({
            alumnos: data.result.map(a => ({id: a.id.toString(), name: a.apellido + ' ' + a.nombre}))
        });
    }

    async fetchDocentes(division_id){
        var params = '';
        if(Andromeda.userEsTutor()){
            params = '?alumno_id=' + this.state.hijosSeleccionados[0].id;
        }
        this.setState({ docentesSeleccionados: [] });
        var data = await Net.get('/docentes' + params);
        this.setState({
            docentes: data.result.map(a => ({id: a.id.toString(), name: a.apellido + ' ' + a.nombre}))
        });
    }

    async fetchHijos(division_id){
        var data = await Net.get('/hijos?division_id=' + division_id);
        if(!data.result[0]){
            cogoToast.error('No hay alumnos asociados con esta cuenta, por favor comunicarse con la institución');
            this.props.history.goBack();
        }else{
            this.setState({
                hijos: data.result.map(h => ({id: h.id.toString(), name: h.apellido + ' ' + h.nombre})),
                hijosSeleccionados: (data.result.length > 1) ? [] : [{id: data.result[0].id, name: data.result[0].apellido + ' ' + data.result[0].nombre}]
            });
        }        
    }

    adjuntarArchivos(files){
    	var archivos = this.state.archivos.slice();
    	files.map(file => {
    		var agregar = true;
	    	archivos.map(archivo => {
	    		if(archivo.name === file.name) agregar = false;
	    	});
	    	if(agregar) archivos.push(file);
    	});
	    this.setState({archivos});
    }

    quitarArchivo(quitar){
    	console.log('quitar');
    	var archivos = this.state.archivos.slice();
    	var indice = -1;
    	archivos.map((a, i) => {
    		if(a.name === quitar.name) indice = i;
    	});
    	if(indice > -1){
    		archivos.splice(indice, 1);
    		this.setState({archivos});
    	}
    }
    
    async enviarArchivo(archivo){
        let fd = new FormData();
        fd.append('archivo', archivo);
        var data = await Net.post('/upload', fd, true, true);
        return data.result.id;
    }
    
    async enviarMensaje(){
        if(this.state.busy){
            return false
        }

        var mensaje = document.getElementById('mensaje').value;
        var asunto = document.getElementById('asunto').value;

        let grupos = [];
        let alumnos = [];
        let docentes = [];

        if(this.state.divisiones && this.state.divisiones.length > 1 && this.state.divisionSeleccionada.length === 0){
            cogoToast.error('Por favor, seleccionar una división')
            return false
        }

        if((!this.state.gruposSeleccionados || this.state.gruposSeleccionados.length === 0) &&
        	(!this.state.alumnosSeleccionados || this.state.alumnosSeleccionados.length === 0) &&
        	(!this.state.docentesSeleccionados || this.state.docentesSeleccionados.length === 0)){
            cogoToast.error('Por favor, seleccionar destinatarios')
            return false
        }

        if(asunto === ''){
            cogoToast.error('Por favor, ingresar el asunto del mensaje')
            return false
        }

        this.setState({ busy:true })

        if(this.state.gruposSeleccionados && this.state.gruposSeleccionados.length > 0){
            this.state.gruposSeleccionados.map((grupo) => {
                grupos.push(grupo.id)
            })
        }
        
        if(this.state.alumnosSeleccionados && this.state.alumnosSeleccionados.length > 0){
            this.state.alumnosSeleccionados.map((alumno) => {
                alumnos.push(alumno.id)
            })
        }

        if(this.state.docentesSeleccionados && this.state.docentesSeleccionados.length > 0){
            this.state.docentesSeleccionados.map((docente) => {
                docentes.push(docente.id);
            })
        }

        let requiere_confirmacion = 0;
        if(this.state.requiere_confirmacion){
            requiere_confirmacion = 1;
        }

        var form_data = new FormData();
        var archivos = [];

        var promises_archivos = this.state.archivos.map(async (archivo) => {
        	var archivo_id = await this.enviarArchivo(archivo);
        	archivos.push(archivo_id);
        });

        await Promise.all(promises_archivos);

        if(this.state.archivos.length > 0){
        	form_data.append('archivos', archivos.join());
        }
        
        if(this.state.gruposSeleccionados && this.state.gruposSeleccionados.length > 0 && this.state.enviar_a_docentes){
            form_data.append('enviar_atodos_docentes', 1);
        }

        if(Andromeda.userEsTutor()){
            form_data.append('alumno_id', this.state.hijosSeleccionados[0].id);
        }

        if(Andromeda.userEsDocente()){
            form_data.append('division_id', this.state.divisionSeleccionada[0].id);
        }
        
        form_data.append('requiere_confirmacion', requiere_confirmacion);
        
        form_data.append('grupos', grupos.join());
        form_data.append('alumnos', alumnos.join());
        form_data.append('docentes', docentes.join());
        form_data.append('titulo', asunto);
        form_data.append('mensaje', mensaje);

        var url = '/tutores/enviar-mensaje';
        if(Andromeda.userEsDocente()){
            url = '/docentes/enviar-mensaje';
        }

        var data = await Net.post(url, form_data, true);

        if(!data.pending){
            cogoToast.success('El mensaje ha sido enviado a todos los destinatarios.', {heading: 'Mensaje enviado'});
        }else{
            cogoToast.info('El mensaje ha sido enviado a dirección y está esperando autorización. Una vez autorizado, será enviado a todos los destinatarios.', {heading: 'Mensaje esperando autorización', hideAfter: 6});
        }

        fetchMensajesEnviados();
        fetchMensajesRecibidos();

        this.props.history.goBack();
    }

    
   
  	render() {
        
        var hayMasDeUnaDivision = !!this.state.divisiones && this.state.divisiones.length > 1;
        var laDivisionEstaSeleccionada = this.state.divisionSeleccionada.length > 0;

        // Show preloader
        if(this.state.showPrealoader){
            return (<div style={{maxWidth:400, width: '100%'}}>   
                    	<MensajesSubmenu page={'nuevo'} />
                    	<div><Spinner /></div>
                    </div>);
        }

        return (
                
                    <div style={{maxWidth:400, width: '100%'}}>   

                    	

                    	<MensajesSubmenu page={'nuevo'} /> 
                        	
                            <div>

                                    {Andromeda.userEsTutor() &&
                                        <div style={{width: 300, alignSelf: 'center', paddingTop: 0}}>
                                            <div>Para enviar un mensaje a un docente, por favor seleccionar a continuación.</div>
                                        </div>
                                    }

                                    {hayMasDeUnaDivision && !Andromeda.userEsTutor() &&
                                    	<div style={{width: 300, alignSelf: 'center'}}>
                                    		<SelectMultiple
                                            style={{zIndex: 2, marginTop: 10}}
                                            options={this.state.divisiones}
                                            selected={this.state.divisionSeleccionada}
                                            txt_button={'Seleccionar una división'}
                                            txt_placeholder={'Buscar división...'}
                                            txt_empty={'No se encontró la división'}
                                            handleAddition={this.onSelectDivision}
                                            handleDelete={this.deleteDivision} />
                                        </div>
                                    }

                                    {laDivisionEstaSeleccionada && Andromeda.userEsTutor() && this.state.hijos && this.state.hijos.length > 1 &&                                       
                                        <div style={{width: 300, alignSelf: 'center'}}>
                                            <SelectMultiple
                                            style={{zIndex: 2, marginTop: 10}}
                                            options={this.state.hijos}
                                            selected={this.state.hijosSeleccionados}
                                            txt_button={'Seleccionar alumno/a'}
                                            txt_placeholder={'Buscar alumno/a...'}
                                            txt_empty={'No se encontró el alumno/a'}
                                            handleAddition={this.addHijo}
                                            handleDelete={this.deleteHijo} />      
                                        </div>
                                    }

                                   {laDivisionEstaSeleccionada && Andromeda.userEsDocente() && 
                                        <div>
                                           
                                            <div>
                                                <div style={{width:300, alignSelf:'center', paddingTop: 3, paddingBottom: 0}}>
                                                    
                                                    {!!this.state.grupos &&
                                                        <SelectMultiple
                                                        style={{zIndex: 2, marginTop: 10}}
                                                        extra={<Switch label="Enviar solo a docentes" checked={this.state.enviar_a_docentes} onChange={e => {
                                                            	if(!this.state.enviar_a_docentes && this.state.gruposSeleccionados === 0){
                                                            		cogoToast.error('Seleccionar al menos un grupo');
                                                            		return false;
                                                            	}
                                                            	this.setState({enviar_a_docentes: e.target.checked});
                                                            }} />}
														options={this.state.grupos}
														btn_seleccionar_todos={true}
														handleSelectAll={this.seleccionarTodosGrupos}
                                                        handleUnSelectAll={this.deSeleccionarTodosGrupos}
														selected={this.state.gruposSeleccionados}
														showExtraPill={this.state.enviar_a_docentes}
														textExtraPill={'solo docentes'}
														txt_button={'Seleccionar grupos'}
														txt_placeholder={'Buscar grupos...'}
														txt_empty={'No se encontraron grupos'}
														handleAddition={this.addGrupo}
														handleDelete={this.deleteGrupo} />          
                                                	}



                                                	{!!this.state.alumnos && Config.docentesPuedenMandarMensajesAPadres &&
                                                    	<SelectMultiple
														style={{zIndex: 2, marginTop: 10}}
														options={this.state.alumnos}
														selected={this.state.alumnosSeleccionados}
														txt_button={'Seleccionar alumnos'}
														txt_placeholder={'Buscar alumnos...'}
														txt_empty={'No se encontraron alumnos'}
														handleAddition={this.addAlumno}
														handleDelete={this.deleteAlumno} />
													}
                                                </div>

                                            </div>
                                            

                                    </div>
                                }


                                	{laDivisionEstaSeleccionada && !!this.state.docentes &&
                                	(!Andromeda.userEsDocente() || Config.docentesPuedenMandarMensajesADocentes) &&
                                        <div style={{width: 300, alignSelf: 'center'}}>
                                            <SelectMultiple
                                            style={{zIndex: 2, marginTop: 10}}
                                            options={this.state.docentes}
                                            selected={this.state.docentesSeleccionados}
                                            txt_button={'Seleccionar docentes'}
                                            btn_seleccionar_todos={Andromeda.userEsDocente()}
                                            handleSelectAll={this.seleccionarTodosDocentes}
                                            handleUnSelectAll={this.deSeleccionarTodosDocentes}
                                            txt_placeholder={'Buscar docentes'}
                                            txt_empty={'No se encontraron docentes'}
                                            handleAddition={this.addDocente}
                                            handleDelete={this.deleteDocente} />      
                                        </div>
                                    }

                                    <div style={{marginBottom: 20, marginTop: 30}}></div>
                                    
                                    <div>
                                    	<Input inputProps={{id: "asunto"}} placeholder="Asunto del mensaje" />
                                    </div>

                                    <div style={{marginTop:20}}>
                                        
                                        <Textarea rows={6} id="mensaje" placeholder="Redactar el mensaje" />
                                    
                                    	<div style={{marginTop: 5, display: 'flex'}}>
                                    		
                                    		<FilePicker onChange={files => this.adjuntarArchivos(files)} />
	                                    	
                                        	 {Andromeda.userEsDocente() &&
                                        	 	<MensajesGuardados onSelected={mensaje => document.getElementById('mensaje').value = mensaje} />
                                        	 }

	                                	</div>

	                                	{this.state.archivos.length > 0 && 
                                            <div>
                                            	{this.state.archivos.map((a, i) => {
                                            		return (<Tag palette="secondary" style={{marginRight: 5}} key={i} onRemove={() => this.quitarArchivo(a)}>{a.name}</Tag>);
                                            	})}
                                            </div>
                                        }
	                                	
	                                	{Andromeda.userEsDocente() &&
                                        	<div style={{width:300, marginBottom: 5, marginTop: 20, alignSelf:'center', flexDirection: 'row' }}>
                                            	<Switch size="large" checked={this.state.requiere_confirmacion} onChange={(e) => this.setState({requiere_confirmacion: e.target.checked})} label="Requiere confirmación de lectura" />
                                        	</div>
                                         }
                                    
                                    </div>
                                    
                                    <div style={{marginBottom:50, marginTop:30}}>
                                        <Button isLoading={this.state.busy} onClick={() => this.enviarMensaje()} palette="secondary">Enviar mensaje</Button>
                                    </div>
                            </div>


                        
                
                  </div>
                
            
        );

    }
  	
}

